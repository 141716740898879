import React from 'react';

import {Map,Base, Marker, Label} from 'rc-bmap'

const { Point } = Base;

const centerPoint = {
    lng: 116.676424,
    lat: 40.403314
  };

const centerPoint2 = {
lng: 116.67992,
lat: 40.36957
};

const centerPoint3 = {
    lng: 116.69011,
    lat: 40.356428
    };

const centerPoint4 = {
  lng: 116.62855,
  lat: 40.350388
  };

const centerPoint5 = {
  lng: 119.600119,
  lat: 25.878124
  };

const Accommodation = ()=> (
    <div>
        <h2>Accommodation</h2>
        <h3>Hotel</h3>
        <ul>
        <li>Name: 福州东湖小镇长乐机场亚朵酒店</li>
        <li>Address: 福建省长乐市数字福建产业园东湖路33号9号研发楼</li>
        <div style={{ height: "40vh" }}>
        <Map ak="WAeVpuoSBH4NswS30GNbCRrlsmdGB5Gv" scrollWheelZoom zoom={16} center={centerPoint5}>
          <Point name="center" lng="119.600119" lat="25.878124" />
          <Marker
            point={centerPoint5}
          />
        </Map>
        </div>
        <li>Price: 430元/夜间（含单早），加早餐30元/份</li>
        <li>Contact: 王经理 13559946549</li>
        </ul>

        {/* <h3>Hotel 1</h3>
        <ul>
        <li>Name: 北京雁栖酒店</li>
        <li>Address: 北京怀柔区雁栖湖西路2号院14号楼</li>
        <div style={{ height: "40vh" }}>
        <Map ak="WAeVpuoSBH4NswS30GNbCRrlsmdGB5Gv" scrollWheelZoom zoom={16} center={centerPoint}>
          <Point name="center" lng="116.676424" lat="40.403314" />
          <Marker
            point={centerPoint}
          />
        </Map>
        </div>
        <li>Price: 1888元/晚</li>
        <li>Contact: 张经理 13486438717 (9:00-18:00)</li>
        </ul>

        <h3>Hotel 2</h3>
        <ul>
        <li>Name: 山水时尚酒店(雁栖店)</li>
        <li>Address: 北京市怀柔区雁栖大街53号</li>
        <div style={{ height: "40vh" }}>
        <Map ak="WAeVpuoSBH4NswS30GNbCRrlsmdGB5Gv" scrollWheelZoom zoom={20} center={centerPoint2}>
          <Point name="center" lng="116.67992" lat="40.36957" />
          <Marker
            point={centerPoint2}
          />
        </Map>
        </div>
        <li>Price: 450元/晚</li>
        <li>Contact: 李经理 13466500208 (9:00-18:00)</li>
        </ul>

        <h3>Hotel 3</h3>
        <ul>
        <li>Name: 麗枫酒店(怀柔雁栖科学城店)</li>
        <li>Address: 北京市怀柔区雁栖路33号院6号楼</li>
        <div style={{ height: "40vh" }}>
        <Map ak="WAeVpuoSBH4NswS30GNbCRrlsmdGB5Gv" scrollWheelZoom zoom={20} center={centerPoint3}>
          <Point name="center" lng="116.69011" lat="40.356428" />
          <Marker
            point={centerPoint3}
          />
        </Map>
        </div>
        <li>Price: 380元/晚</li>
        <li>Contact: 段经理 15011202620 (9:00-18:00)</li>
        </ul>

        <h3>Hotel 4</h3>
        <ul>
        <li>Name: 北京怡湾酒店</li>
        <li>Address: 北京市怀柔区怀柔镇郭卧路郭家坞东孤子山脚下临526号</li>
        <div style={{ height: "40vh" }}>
        <Map ak="WAeVpuoSBH4NswS30GNbCRrlsmdGB5Gv" scrollWheelZoom zoom={16} center={centerPoint4}>
          <Point name="center" lng="116.62855" lat="40.350388" />
          <Marker
            point={centerPoint4}
          />
        </Map>
        </div>
        <li>Price: 800元/晚</li>
        <li>Contact: 魏经理 13911130303 (9:00-18:00)</li>
        </ul>

        <h2>Travel</h2>
        <h3><b style={{ color: "red" }}>NOTE</b>: To abide by the relevant Beijing's regulations about the COVID-19 prevention, please follow the <b style={{ color: "red" }}>COVID-19 prevention requirements</b> in <b style={{ color: "red" }}><a href="https://mp.weixin.qq.com/s/OKwsgIXFYr06hK14eZZS7Q" target="_blank">https://mp.weixin.qq.com/s/OKwsgIXFYr06hK14eZZS7Q</a></b>.</h3>
        <img width="700" src={process.env.PUBLIC_URL + '/travel.jpg'} /> */}
    </div>
);

export default Accommodation;