import { findByLabelText } from '@testing-library/react';
import React from 'react';

import styles from './Speaker.module.scss';
import speaker_1 from '../imgs/keynote_speaker_1.png';

import chair_1 from '../imgs/Lu FANG.jpg';
import chair_2 from '../imgs/daniel.jpg';
import chair_3 from '../imgs/zhaiguangtao.jpg';
import chair_4 from '../imgs/karamlina.jpg';
import chair_5 from '../imgs/meitao.png';
import chair_6 from '../imgs/wangruiping.jpg';
import chair_7 from '../imgs/qiaohui.jpg';
import chair_8 from '../imgs/tapusadriana.jpg';  
import chair_9 from '../imgs/jimengqi.png';
import chair_10 from '../imgs/zhaozhou.jpg';
import chair_11 from '../imgs/luoshan.jpg';
import chair_12 from '../imgs/likun.jpg';
import chair_13 from '../imgs/zhangfu.jpg';
import chair_14 from '../imgs/yangfeng.jpg';
import chair_15 from '../imgs/Jian Pei.jpg';
import chair_16 from '../imgs/David Brady.jpg';
import chair_17 from '../imgs/Haozhe Lin.jpg';
import chair_18 from '../imgs/Jiangtao Gong.jpg';
import chair_19 from '../imgs/Jie Song.jpg';
import chair_20 from '../imgs/Tao Yu.jpg';
import chair_21 from '../imgs/Chen Xun.jpg';
import chair_22 from '../imgs/Xiaohui Chen.jpg';
import chair_23 from '../imgs/Can Liu.jpg';
import chair_24 from '../imgs/Jiantao Zhou.jpg';


const Speaker = ({ name, title, time, paper_title, photo_url}) => {
    console.log(name);
    console.log(title);
    console.log(paper_title);
    return (
        <div className={styles['speaker']}>
            <div className={styles['speaker_center_wrapper']}>
                <h4 className={styles['subtle']}><i>{time}</i></h4>
                <img width="200" className={styles['speaker_photo']} src={photo_url} alt="photo"/>
                <h3 >{name}</h3>
                <h4 className={styles['subtle']}>{title}</h4> <br />
                <h4>{paper_title}</h4>
            </div>
            <div className={styles['divider_light']}></div>
        </div>
    )
};



const Committee = ()=> (
    <div>
        <h2>General Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_1} />
        </div>
        <div>
        <p>Lu Fang, <br/>
            Tsinghua University, <br/>
            China</p>
        </div>
        </div>
        
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_2} />
        </div>
        <div>
        <p>Daniel Povey, <br/>
            Xiaomi, <br/>
            China</p>
        </div>
        </div> */}  


        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_15} />
        </div>
        <div>
        <p>Jian Pei, <br/>
            Duke University, <br/>
            USA</p>
        </div>
        </div>
        

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_3} />
        </div>
        <div>
        <p>Guangtao Zhai, <br/>
            Shanghai Jiao Tong  <br/>
            University, China</p>
        </div>
        </div>
        </div>

        </ul>

        {/* <h2>Honorary Program Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_4} />
        </div>
        <div>
        <p>Lina J. Karam, <br/>
           Lebanese American University,  <br/>
           Republic of Lebanon </p>
        </div>
        </div>
        </div>

        </ul>         */}

        <h2>Program Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_5} />
        </div>
        <div>
        <p>Tao Mei, <br/>
           JD Explore Academy, <br/>
           China</p>
        </div>
        </div> */}

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_6} />
        </div>
        <div>
        <p>Ruiping Wang, <br/>
            Chinese Academy of <br/>
            Sciences, China</p>
        </div>
        </div>
        </div>
        </ul>

        <h2>Publication Chair</h2>
        <ul> 

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_7} />
        </div>
        <div>
        <p>Hui Qiao, <br/>
           Tsinghua Univerisity,  <br/>
           China</p>
        </div>
        </div> */}
        
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_8} />
        </div>
        <div>
        <p> Adriana Tapus, <br/>
            Doctoral School Institut <br/>
            Polytechnique de Paris, France</p>
        </div>
        </div> */}


        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_22} />
        </div>
        <div>
        <p> Xiaohui Chen, <br/>
            University of Illinois at<br/>
             Urbana-Champaign, <br/>
             USA</p>
        </div>
        </div>

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_9} />
        </div>
        <div>
        <p> Mengqi Ji, <br/>
            Beihang University, <br/>
            China</p>
        </div>
        </div>

        </div>
        </ul>

        <h2>Presentation Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_9} />
        </div>
        <div>
        <p>Mengqi Ji, <br/>
           Beihang University,  <br/>
           China</p>
        </div>
        </div> */}
        
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_10} />
        </div>
        <div>
        <p>Zhou Zhao, <br/>
           Zhejiang Univerisity, <br/>
           China</p>
        </div>
        </div> */}

        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_11} />
        </div>
        <div>
        <p>Shan Luo, <br/>
           King's College London, <br/>
           UK</p>
        </div>
        </div> */}

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_21} />
        </div>
        <div>
        <p>Xun Chen, <br/>
            University of Science<br/> 
            and Technology <br/>
            of China, China</p>
        </div>
        </div>

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_24} />
        </div>
        <div>
        <p>Jiantao Zhou, <br/>
            University of Macau, <br/>
            China</p>
        </div>
        </div>

        </div>
        </ul>

        <h2>Demo Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_12} />
        </div>
        <div>
        <p>Kun Li, <br/>
           Tianjin University,  <br/>
           China</p>
        </div>
        </div> */}
        
        {/* <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_13} />
        </div>
        <div>
        <p>Fu Zhang, <br/>
           University of Hong Kong, <br/>
           China</p>
        </div>
        </div> */}

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_18} />
        </div>
        <div>
        <p>Jiangtao Gong, <br/>
            Tsinghua University, <br/>
           China</p>
        </div>
        </div>


        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_23} />
        </div>
        <div>
        <p>Can Liu, <br/>
            City University of Hong Kong, <br/>
           China</p>
        </div>
        </div>


        </div>
        </ul>

        {/* <h2>International Liaison Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_14} />
        </div>
        <div>
        <p>Feng Yang, <br/>
           Google Research</p>
        </div>
        </div>
        </div>
        </ul> */}

        <h2>Tutorial Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        
        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_19} />
        </div>
        <div>
        <p>Jie Song, <br/>
            ETH Zurich, <br/>
            Switzerland</p>
        </div>
        </div>

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_20} />
        </div>
        <div>
        <p>Tao Yu, <br/>
            Tsinghua University, <br/>
           China</p>
        </div>
        </div>
        </div>
        </ul>


        <h2>Grand Challenge Chair</h2>
        <ul>

        <div style={{display:"flex",flexWrap: 'wrap',justifyContent: "space-between"}}>
        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_16} />
        </div>
        <div>
        <p>David Brady, <br/>
            University of Arizona, <br/>
            USA</p>
        </div>
        </div>

        <div style={{display:"flex",flexDirection:'column',justifyContent: "center",textAlign: "center"}}>
        <div>
        <img width="150" left="100" className={styles['speaker_photo']} src={chair_17} />
        </div>
        <div>
        <p>Haozhe Lin, <br/>
            Tsinghua University, <br/>
           China</p>
        </div>
        </div>

        </div>
        </ul>

        <h2>Advisory Committee</h2>
        <ul>
        <li>C. L. Philip Chen, University of Macau, China</li> 
        <li>Xilin Chen, Institute of Computing Technology, Chinese Academy of Sciences, China</li> 
        <li>Yike Guo,  Imperial College London, China</li>
        <li>Ping Ji, The City University of New York, China</li>
        <li>Licheng Jiao, Xidian University, China</li>
        <li>Ming Li, University of Waterloo, Canada</li>
        <li>Chenglin Liu, Institute of Automation, Chinese Academy of Sciences, China</li>
        <li>Derong Liu, University of Illinois at Chicago, USA</li> 
        <li>Hong Liu, Peking University, China </li>
        <li>Hengtao Shen, University of Electronic Science and Technology of China</li>
        <li>Yuanchun Shi, Tsinghua University, China</li>
        <li>Yongduan Song, Chongqing University, China</li>
        <li>Fuchun Sun, Tsinghua University, China</li>
        <li>Jianhua Tao, Institute of Automation, Chinese Academy of Sciences, China</li>
        <li>Guoyin Wang, Chongqing University of Posts & Telecommunications, China</li>
        <li>Weining Wang, Beijing University of Posts and Telecommunications, China</li>
        <li>Xiaokang Yang, Shanghai Jiao Tong University, China</li>
        <li>Changshui Zhang, Tsinghua University, China</li>
        <li>Lihua Zhang, Fudan University, China</li>
        <li>Song-Chun Zhu, Peking University, China</li>
        <li>Wenwu Zhu, Tsinghua, China</li>
        <li>Yueting Zhuang, Zhejiang University, China</li>
        </ul>

    </div>
);

export default Committee;