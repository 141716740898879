import React from 'react';
import styles from './Registration.module.scss';
import a1 from '../imgs/AlipayQRCode.png'
import a2 from '../imgs/tutorial code.jpg'
import a3 from '../imgs/AlipayQRCode2023.png'
const Registration = ()=> (
    <div>
        <h1>Registration</h1>
        <h2>Conference Registration</h2>
        <p><b>Registration for CICAI 2023 participation</b></p>
        <table bgcolor="AliceBlue" cellSpacing="30">
          <tr>
            <th><b>Confirmation before registration</b></th>
            <th><b>Fees (RMB)</b></th>
            <th ><b>Registration time</b></th>
          </tr>
          <tr>
            <td>Paper Author (Up to 2 papers)</td>
            <td>￥3000</td>
            <td rowSpan="2"> tbd. </td>
          </tr>
          <tr>
            <td>Attendee</td>
            <td>￥2000</td>
            <td></td>
          </tr>
        </table>
        <br></br>
        <p><b>Way of Registration and Payment</b></p>
        <p><b style={{ color: "red" }}>TIPS</b>: For successful registration, please finish the following <b style={{ color: "red" }}>TWO</b> steps: <b style={{ color: "red" }}>clicking the following button</b> and <b style={{ color: "red" }}>paying through the following QR code</b>.</p>
        <a class="btn" href="https://www.wjx.top/vj/rMFONxc.aspx" class={styles['registration_btn']}>Register now!</a>
        <ul>
          <li>Ways of payment: Alipay and offline bank transfer. The Alipay QR code:</li>
          <img src={a3} width="250px" height="250px" />
          <p><b>Attention: </b>You have to append “CICAI + XXX (your name)+ Affiliation” to ensure that we can get the correct information, and confirm the name (or organization) you filled in the registration chart is the same as the information you append when you are getting the registration fee paid.</p>
          <li>Account information of offline bank transfer:</li>
          <p>Account name: 中国人工智能学会<br></br>
             Account number: 0200002909200166203<br></br>
             Account bank: 中国工商银行股份有限公司北京新街口支行营业室<br></br>
             Address: 143 Xizhimen Nei Dajie, Xicheng District, Beijing, China<br></br>
             <b>Attention: </b>If you choose the offline transfer, you must first complete the transfer before registering. Be sure to indicate your name and organization in the postscript when you transfer, be sure to upload a screenshot of the payment voucher during the registration.</p>
          <li>After payment, click “Register now” to upload your registration information.</li>
        </ul>
        <p><b>Notices</b></p>
        <ul>
          <li>Each accepted paper must have at least one author regular registered with the conference. Papers that are not regular registered will not be published.</li>
          <li>If you use Alipay to pay the registration fee for the conference, please avoid refunds. If you have refund request, please contact <a href = "mailto: cicai@caai.cn">cicai@caai.cn</a></li>
        </ul>


        <h2>Tutorial Registration</h2>
        <p>Tutorial Registration has been closed, please register as the tips above.</p>
    </div>
);

export default Registration;

