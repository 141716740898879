import React from 'react';
import {Map,Base, Marker, Label} from 'rc-bmap'

const { Point } = Base;

const centerPoint = {
    lng: 119.602816,
    lat: 25.877787
  };


const Venue = ()=> (
    <div>
        <h2>Venue</h2>
        <div style={{ height: "40vh" }}>
        <Map ak="WAeVpuoSBH4NswS30GNbCRrlsmdGB5Gv" scrollWheelZoom zoom={16} center={centerPoint}>
          <Point name="center" lng="119.602816" lat="25.877787" />
          <Marker
            point={centerPoint}
          />
        </Map>
    </div>
    <p>
    <br></br>
    <br></br>
    </p>

    <p>Position: <b>福州东湖万豪酒店</b><br></br>
    <br></br>
    Address: <b>福州长乐区东湖数字小镇壶江路 18 号</b><br></br>
    </p>
    </div>
);

export default Venue;