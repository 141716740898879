import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Registration.module.scss';

const callfordemo = ()=> (
    <div>
        <h2>Call for Demos</h2>
        <p> The CAAI International Conference on Artificial Intelligence (CICAI 2023) technical program committee invites submissions of demos for the demo track. The aim of the demo track is to provide an opportunity to showcase applications of AI technologies and encourage discussions between theory and practice. Accepted demo papers will be included in the conference proceedings.  </p>
        <p> We encourage submissions that describe innovative, high-quality AI research addressing real-world problems. All topics related to CICAI, including but not limited to the topics listed on the main conference website, are welcome. </p>
        <h2>Best Demo Awards</h2>
        <p>One best demo award will be given to honor outstanding demonstrations that exhibit high originality and practicability.</p>
        <h2>Submission Guidelines and Review Policy</h2>
        <p>Authors are required to submit a demo paper <b>(4 pages technical content + 2 pages reference)</b>. The papers should describe the design of the system, the AI techniques used, the application domain, and what attendees expect to experience during the demonstration. The papers should not have been previously published or accepted for publication in substantially similar form in any peer-reviewed venue including journal, conference or workshop, or archival forum. The papers format, submission process, and deadline all follow the regular paper submission.</p>
        <p>Each demo paper should be accompanied by a short video (less than 5 minutes) clearly describing the demo scenarios. Please uploaded the video to any hosting site and include the link in your paper submission.</p>
        <p>Reviewing of the submitted papers will be single-blind.</p>
        <p>At least one author of each accepted paper is required to participate in the conference and present the work. </p>
        <h2>Demos Submission</h2>
        <p>Demos submission will be handled via the CMT system. Please submit your demo at <a href="https://cmt3.research.microsoft.com/CICAI2023" target="_blank">https://cmt3.research.microsoft.com/CICAI2023.</a></p>
        <p>
            The instructions and templates are showed in <Link to="/instruction">Instructions for Authors</Link>.
        </p>
        <h2>Modes of Demo Presentation</h2>
        <p>The demo will be performed in the traditional demo mode, where the demonstration is presented in a booth during the demonstrations session.</p>
        <h2>Demo Chairs</h2>
        <ul>
            <li>Jiangtao Gong (Tsinghua University)</li>
            email：<a href="mailto:gongjiangtao@air.tsinghua.edu.cn">gongjiangtao@air.tsinghua.edu.cn</a>
        </ul>
        <ul>
            <li>Can Liu (City University of Hong Kong)</li>
            email：<a href="mailto:canliu@cityu.edu.hk">canliu@cityu.edu.hk</a>
        </ul>
        <p>For any question, please contact the demo chairs.</p>
    </div>
);

export default callfordemo;