import React from 'react';
import styled from 'styled-components'
import { useTable } from 'react-table';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 0px solid black;
    tr {
    :nth-child(even)
        {background: #F5F5F5
        }
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-family: Georgia, serif;
      :last-child {
        border-right: 0;
      }
      
    }
  }
`




function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Technical = ()=> {

    const columns = React.useMemo(
        () => [
          {
            Header: '@ Zhongwu Hall, Room 105, Yanxi Hotel Conference Center ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
                colSpan: 2, 
              },
              {
                Header: 'Title',
                accessor: 'Title',
                colSpan: 0,
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data1 = React.useMemo(() =>
     [
     {
     Time: '09:00-09:35',
     Speaker: 'Renjie Liao, University of British Columbia',
     Title: 'Generalization bound of GNNs',
     },
     {
    Time:'09:35-10:10',
     Speaker: 'Dong Tian, InterDigital',
     Title: 'Graph: Its Concept and Application in Point Cloud Processing',
     },
     {
        Time:'10:10-10:45',
         Speaker: 'Siheng Chen, Shanghai Jiao Tong University',
         Title: 'Graph Learning for Collective Intelligence',
    },
    {
        Time:'10:45-11:20',
         Speaker: 'Chuan Shi, Beijing University of Posts and Telecommunications',
         Title: 'Recent Development of Heterogeneous Graph Neural Networks',
    },
    {
        Time:'11:20-11:55',
         Speaker: 'Yue Gao, Tsinghua University',
         Title: 'Hypergraph Computing: Method and Practice',
    },
     ],
     []
    )

    const columns1 = React.useMemo(
        () => [
          {
            Header: 'GigaVision WorkShop',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
                colSpan: 2,
              },
              {
                Header: 'Title',
                accessor: 'Title',
                colSpan:3
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data2 = React.useMemo(() =>
     [
     {
     Time: '09:00-09:30',
     Speaker: 'Bin Fang, Tsinghua University',
     Title: 'Human-Robot Interaction for Dexterous Manipulation',
     },
     {
     Time:'09:30-10:00',
     Speaker: 'Yiwen Wang, Hong Kong University of Science and Technology',
     Title: 'Brain Machine Interfaces—the Era of Avatar',
     },
     {
        Time:'10:00-10:30',
         Speaker: 'Yuntao Wang, Tsinghua University',
         Title: 'Physiological Sensing Techniques on Modern Smart Devices',
    },
    {
        Time:'10:30-11:00',
         Speaker: 'Yun Huang, University of Illinois at Urbana-Champaign',
         Title: 'Designing Conversational AI for Human-Human Interaction',
    },
     ],
     []
    )

    const columns2 = React.useMemo(
        () => [
          {
            Header: '@ Zhaohua Hall, Yanxi Hotel Conference Center',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data3 = React.useMemo(() =>
     [
     {
     Time: '09:00-09:30',
     Speaker: 'Weiming Dong, Institution of Automation, CAS',
     Title: 'Image Style Transfer',
     },
     {
     Time:'09:30-10:00',
     Speaker: 'Zhiyao Duan, University of Rochester',
     Title: 'A Brief History of Interactive Music Systems',
     },
     {
        Time:'10:00-10:30',
         Speaker: 'Yun Wang, Beihang University',
         Title: 'Responsible AI and Design Innovation',
    },
    {
        Time:'10:30-11:00',
         Speaker: 'Di Lu, Central Conservatory of Music',
         Title: 'Towards the Implementation of an Automatic Composition System for Pop Songs',
    },
     ],
     []
    )


    const columns3 = React.useMemo(
        () => [
          {
            Header: '@ Meeting Room E, Room 105, Yanxi Hotel',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data4 = React.useMemo(() =>
     [
     {
     Time: '13:30-14:00',
     Speaker: 'Hongning Wang, University of Virginia',
     Title: 'Comparative Explanation of Recommendations',
     },
     {
     Time:'14:00-14:30',
     Speaker: 'Junlan Feng, China Mobile',
     Title: 'The Human-Machine Dialog Technology: The Companion Journey in the Industry and Academia ',
     },
     {
        Time:'14:30-15:00',
         Speaker: 'Xipeng Qiu, Fudan University',
         Title: 'Towards Efficient Natural Language Processing',
    },
    {
        Time:'15:00-15:30',
         Speaker: 'Nanyun Peng, University of California Los Angeles',
         Title: 'Controllable Text Generation Beyond Auto-Regressive Models',
    },
     ],
     []
    )

    const columns4 = React.useMemo(
        () => [
          {
            Header: '@ Zhongwu Hall, Room 105, Yanxi Hotel Conference Center',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data5 = React.useMemo(() =>
     [
     {
     Time: '13:30-14:05',
     Speaker: 'Xiaowei Zhou, Zhejiang University',
     Title: 'Human Performance Capture with Few RGB Cameras',
     },
     {
     Time:'14:05-14:40',
     Speaker: 'Juyong Zhang, University of Science and Technology of China',
     Title: 'Monocular Camera based Human Modeling and Animation',
     },
     {
        Time:'14:40-15:15',
         Speaker: 'Xiaoguang Han, The Chinese University of Hong Kong',
         Title: 'Learning to Reconstruct 3D Garment from Single Images',
    },
    {
        Time:'15:15-15:50',
         Speaker: 'Yebin Liu, Tsinghua University',
         Title: 'Towards AI Avatar',
    },
    {
        Time:'15:50-16:25',
         Speaker: 'Siyu Tang, ETH Zurich',
         Title: 'Inhabiting the virtual',
    },
     ],
     []
    )

    const columns5 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data6 = React.useMemo(() =>
     [
     {
     Time: '09:50-10:00',
     Speaker: 'Weilin Sun, Xiangxian Li,Yuqing Wang, Yuze Zheng,Xiangxu Meng, Lei Meng',
     Title: 'Sequential Fusion of Multi-view Video Frames for 3D Scene Generation',
     },
     {
     Time:'10:00-10:10',
     Speaker: 'Haibiao Xuan,Jinsong Zhang, Kun Li',
     Title: 'MHPro: Multi-Hypothesis Probabilistic Modeling for Human Mesh Recovery',
     },
     {
        Time:'10:10-10:20',
         Speaker: 'Ming Guo, Shunfei Wang,Zhibo Wang, Ming Lu,Xiufen Cui, Xiao Ling,Feng Xu',
         Title: '3D Face Cartoonizer: Generating Personalized 3D Cartoon Faces from 2D Real Photos with a Hybrid Dataset',
    },
    {
        Time:'10:20-10:30',
         Speaker: 'Yanli Liu, Bochen Guan,Weiyi Li, Qinwen Xu,Shuxue Quan',
         Title: 'SMOF: Squeezing More Out of Filters Yields Hardware-Friendly CNN Pruning',
    },
    {
        Time:'10:30-10:40',
         Speaker: 'Yaping Zhao, Haitian Zheng,Mengqi Ji, Ruqi Huang',
         Title: 'Cross-Camera Deep Colorization',
    },
     ],
     []
    )

    const columns6 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data7 = React.useMemo(() =>
     [
     {
     Time: '10:50-11:00',
     Speaker: 'Fenyu Hu, Zeyu Cui,Shu Wu, Qiang Liu,Jinlin Wu, Liang Wang,Tieniu Tan',
     Title: 'Second-order Global Attention Networks for Graph Classification and Regression',
     },
     {
     Time:'11:00-11:10',
     Speaker: 'Kaifang Wu, Yonghui Yang,Kun Zhang, Le Wu,Jing Liu, Xin Li',
     Title: 'Multi-Relational Cognitive Diagnosis for Intelligent Education',
     },
     {
        Time:'11:10-11:20',
         Speaker: 'Jun Wang, Qianlong Wang,Zhiyuan Wen, Ruifeng Xu',
         Title: 'Interactive Fusion Network with Recurrent Attention for Multimodal Aspect-based Sentiment Analysis',
    },
    {
        Time:'11:20-11:30',
         Speaker: 'Huimei Tang, Zengyang Shao, Yutao Zhang, Lijia Ma,Qiuzhen Lin',
         Title: 'Dynamic Network Embedding by Using Sparse Deep Autoencoder',
    },
    {
        Time:'11:30-11:40',
         Speaker: 'Rui Ji; Yibing Zhan,Rong Zhang',
         Title: 'Deep Graph Convolutional Networks Based on Contrastive Learning: Alleviating Over-smoothing Phenomenon',
    },
     ],
     []
    )

    const columns7 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data8 = React.useMemo(() =>
     [
     {
     Time: '14:10-14:20',
     Speaker: 'Aihua Yin, Weiwen Lu,Sidong Wang, Hongzhi You,Ru-Yuan Zhang, Da-Hui Wang, Zonglei Zhen,Xiaohong Wan',
     Title: 'Visual Perception Inference on Raven’s Progressive Matrices by Semi-Supervised Contrastive Learning',
     },
     {
     Time:'14:20-14:30',
     Speaker: 'Zhengyin Chen, Kun Liu,Wenpin Jiao',
     Title: 'A Genetic Algorithm for Causal Discovery Based on Structural Causal Model',
     },
     {
        Time:'14:30-14:40',
         Speaker: 'Zaipeng Xie, Cheng Ji,Yufeng Zhang',
         Title: 'Deep Skill Chaining with Diversity for Multi-agent Systems',
    },
    {
        Time:'14:40-14:50',
         Speaker: 'Jiyuan Zheng, Shaoliang Yu,Jiamin Wu, Yuyan Wang,Chenchen Deng, Zhu Lin',
         Title: 'A Novel In-sensor Computing Architecture based on Single Photon Avalanche Diode and Dynamic Memristor',
    },
     ],
     []
    )

    const columns8 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data9 = React.useMemo(() =>
     [
     {
     Time: '15:00-15:10',
     Speaker: 'Tianqi Zhao, Ming Kong,Kun Kuang, Zhengxing Huang, Qiang Zhu,Fei Wu',
     Title: 'Connecting Patients with Pre-diagnosis: A Multiple Graph Regularized Method for Mental Disorder Diagnosis',
     },
     {
     Time:'15:10-15:20',
     Speaker: 'Yuxin Su, Ziling Miao, Hong Liu',
     Title: 'Audio-Visual Multi-Person Keyword Spotting via Hybrid Fusion',
     },
     {
        Time:'15:20-15:30',
         Speaker: 'Jian Liu, Yahong Han',
         Title: 'Maintaining Structural Information by Pairwise Similarity for Unsupervised Domain Adaptation',
    },
    {
        Time:'15:30-15:40',
         Speaker: 'Zihao Chen, Longhao Zou,Gabriel-Miro Muntean,Xu Wang, Long Xu',
         Title: 'EdgeVR360: Edge-assisted Multiuser-oriented Intelligent 360-degree Video Delivery Scheme over Wireless Network',
    },
     ],
     []
    )

    const columns9 = React.useMemo(
      () => [
        {
          Header: '@ Outside the Conference Rooms / Haiyan Hall, Yanxi Hotel',
          columns: [
            {
              Header: 'Index',
              accessor: 'Index',
            },
            {
              Header: 'Title',
              accessor: 'Title',
            },
          ],
        },
      ],
      []
    )
  
  const data10 = React.useMemo(() =>
   [
   {
   Index: '1',
   Title: 'GLRNet: Gas Leak Recognition via Temporal Difference in Infrared Video',
   },
   {
    Index: '2',
    Title: 'ATC-WSA: Working State Analysis for Air Traffic Controllers',
    },
    {
      Index: '3',
      Title: '3D-Producer: A Hybrid and User-Friendly 3D Reconstruction System',
      },
    {
        Index: '4',
        Title: '3D Human Pose Estimation Based on Multi-feature Extraction',
    },
    {
      Index: '5',
      Title: 'Visual Localization through Virtual Views',
   },
    {
    Index: '6',
    Title: 'A Synchronized Multi-view System for Real-time 3D Hand Reconstruction',
    },
    {
    Index: '7',
    Title: 'Monocular Real-time Human Geometry Reconstruction',
    },      
    {
      Index: '8',
      Title: 'Contactless Cardiogram Reconstruction Based on the Wavelet Transform via Continuous wave Radar',
      },    
    {
        Index: '9',
        Title: 'Intelligent Data Extraction System for RNFL Examination Reports',
    },    
    {
      Index: '10',
      Title: 'Gesture Interaction for Gaming Control Based on an Interferometric Radar',
  },   
  {
    Index: '11',
    Title: 'EasyPainter: Customizing Your Own Paintings',
},   
{
  Index: '12',
  Title: 'An Accelerating Allen Brain Institute’s Large-scale Computational Model of Mice Primary Visual Cortex',
},    
{
  Index: '13',
  Title: 'NeRFingXR: An interactive XR Tool based on NeRFs',
},  
{
  Index: '14',
  Title: 'A Brain-controlled Mahjong Game with Artificial Intelligence Augmentation',
},  
{
  Index: '15',
  Title: 'VAFA: a Visually-Aware Food Analysis System for Socially-Engaged Diet Management',
},  
{
  Index: '16',
  Title: 'XIVA: An Intelligent Voice Assistant with Scalable Capabilities for Educational Metaverse',
},  
{
  Index: '17',
  Title: 'Artistic Portrait Applet, Robot, and Printer',
}, 
{
  Index: '18',
  Title: 'Sim-to-Real Hierarchical Planning and Control System for Six-legged Robot',
}, 
{
  Index: '19',
  Title: 'Weld Defect Detection and Recognition System Based on Static Point Cloud Technology',
}, 
   ],
   []
  )
    return (
        <div>
            <h4>The specific schedule can be derived in the <a href={process.env.PUBLIC_URL + '/cicai_schedule.pdf'} target="_blank">schedule document.</a></h4>
            <h1 align="center" style={{ color: "red" }}>Schedule (July 22,2023)</h1>
            <h3>Opening Ceremony: 09:00-09:50</h3>
            <h4>Venue:Yunfan Hall,Fuzhou Digital China Covention and Exhibition Center</h4>
            {/* <Styles><Table columns={columns} data={data1} /></Styles> */}
            <br></br>
          
            <h3>GigaVision Workshop: 10:00-14:50 </h3>
            <table border="1">
              <tr >
              <td  colspan="2" bgcolor='gray'>GigaVision WorkShop</td>
              </tr>
              <tr>
              <td>July 22,2023</td>
              <td >Venue：Fuzhou/Binhai Hall,Fuzhou Marriott Hotel Riverside</td>
              </tr>
              <tr>
              <td>Time</td>
              <td>Topic</td>
              </tr>
              <tr>
              <td>10:00-10:50</td>
              <td>GigaDetection Challenge</td>
              </tr>
              <tr>
              <td>11:00-11:50</td>
              <td>GigaReconstruction Challenge</td>
              </tr>
              <tr>
              <td>14:00-14:50</td>
              <td>GigaTrajectory Challenge</td>
              </tr>
            </table>


            
            <h3>Tutorial: 14:00-17:20 </h3>
            
            <table border="1">
              <tr>
                <td colspan="4" bgcolor='gray'>Tutorial ：Embodied AI</td>
               
              </tr>
              <tr>
                <td colspan="3">July 22,2023</td>
                <td>Venue：208，Fuzhou Digital China Covention and Exhibition Center</td>
              </tr>
              <tr>
                <td colspan="4">Chair：ZHAO Hao,Assistant Professor at Tsinghua University</td>
                
              </tr>
              <tr>
                <td>Time</td>
                <td>Topic</td>
                <td colspan="2">Speaker</td>
              </tr>
              <tr>
                <td>14:00-14:40</td>
                <td>Vision-Centric Autonomous Driving</td>
                <td>ZHAO Hang</td>
                <td>Assistant Professor at IIIS, Tsinghua University, Principle Investigator of MARS Lab</td>
              </tr>
              <tr>
                <td>14:40-15:20</td>
                <td>Toward Human-Centric Embodied AI: Capturing, Understanding, and Synthesizing Human-Object Interaction</td>
                <td>YI Li</td>
                <td>Assistant Professor at IIIS, Tsinghua University</td>
              </tr>
              <tr>
                <td>15:20-16:00</td>
                <td>Embodied AI in the body's eye</td>
                <td>ZHU Yixin</td>
                <td>Assistant Professor at Institute for Artificial Intelligence, Peking University</td>
              </tr>
              <tr>
                <td>16:00-16:40</td>
                <td>Neural Simulation for Embodied AI</td>
                <td>LIAO Yiyi</td>
                <td>Assistant Professor at Zhejiang University</td>
              </tr>
              <tr>
                <td>16:40-17:20</td>
                <td>Vision-and-Language Navigation in 3D Environments</td>
                <td>CHEN Shizhe</td>
                <td>Researcher at WILLOW Project-team, Inria Paris</td>
              </tr>
            </table>
            <br></br>
            <table  border='1'>
              <tr>
                <td colspan='4' bgcolor='gray'>Tutorial: Self-supervised Learing, Pretraining and Large models</td>
                
              </tr>
              <tr>
                <td colspan='3'>July 22,2023</td>
                <td>Venue：209, Fuzhou Digital China Covention and Exhibition Center</td>
              </tr>
              <tr>
                <td colspan='4'>Chair：BAI Bing, Researcher at Qiyuan Lab</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>Topic</td>
                <td colspan='2'>Speaker</td>
              </tr>
              <tr>
                <td>14:00 - 14:50</td>
                <td>Large Language Models and Frontier Applications: BatGPT</td>
                <td>ZHAO Hai</td>
                <td>Professor of the Department of Computer Science and Engineering, Shanghai Jiao Tong University</td>
              </tr>
              <tr>
                <td>14:50 - 15:40</td>
                <td>Advances on Large Models and Applications in Autonomous Driving</td>
                <td>LI Hongyang</td>
                <td>Research Scientist and PI at Shanghai Artificial Intelligence Laboratory</td>
              </tr>
              <tr>
                <td>15:40 - 16:30</td>
                <td>A Tutorial on Large Multi-modal Generative Models</td>
                <td>BAO Fan</td>
                <td>Ph.D. Candidiate of Tsinghua University</td>
              </tr>
              <tr>
                <td>16:30 - 17:20</td>
                <td>Pre-training of Large Visual Models for Large Scale General-Purpose Applications</td>
                <td>LIU Yu</td>
                <td>Senior Research Director of SenseTime Group,Principal Investigator at Shanghai AI Laboratory</td>
              </tr>
      
            </table>
            <br></br>
            <table border='1'>
              <tr>
                <td colspan='4' bgcolor='gray'>Tutorial:AI with Microscopy</td>
                
              </tr>
              <tr>
                <td colspan='3'>July 22,2023</td>
                <td>Venue：210, Fuzhou Digital China Covention and Exhibition Center</td>
              </tr>
              <tr>
                <td colspan='4'>Chair：WU Jiamin, Assistant Professor in the Department of Automation at Tsinghua University</td>
              
              </tr>
              <tr>
                <td>Time</td>
                <td>Topic</td>
                <td colspan='2'>Speaker</td>
              </tr>
              <tr>
                <td>14:00-14:40</td>
                <td>AI-empowered Quantitative Phase Microscopy for Label-free Live-cell Analysis</td>
                <td>ZHOU Renjie</td>
                <td>Assistant Professor, Department of Biomedical Engineering, The Chinese University of Hong Kong</td>
              </tr>
              <tr>
                <td>14:40-15:20</td>
                <td>Deep Self-supervised Denoising Enables Ultrasensitive Fluorescence Imaging Beyond the Shot-noise Limit</td>
                <td>LI Xinyang</td>
                <td>Postdoctoral Fellow at the Department of Automation, Tsinghua University</td>
              </tr>
              <tr>
                <td>15:20-16:00</td>
                <td>Physics-Informed Learning for High-resolution Light-field Reconstruction</td>
                <td>YANG Jingyu</td>
                <td>Professor with the School of Electrical and Information Engineering, Tianjin University</td>
              </tr>
              <tr>
                <td>16:00-16:40</td>
                <td>Quantitatively mapping local quality at super-resolution scale by rolling Fourier ring correlation</td>
                <td>LI Haoyu</td>
                <td>Professor at Harbin Institute of Technology</td>
              </tr>
            </table>
            <br></br>
            <table border='1'>
              <tr>
                <td colspan='4' bgcolor='gray'>Tutorial: Neuromorphic Computing</td>
              </tr>
              <tr>
                <td colspan='3'>July 22,2023</td>
                <td>Venue：211, Fuzhou Digital China Covention and Exhibition Center</td>
              </tr>
              <tr>
                <td colspan='4'>Chair：DENG Lei, Associate Professor at Center for Brain Inspired Computing Research (CBICR), Tsinghua University</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>Topic</td>
                <td colspan='2'>Speaker</td>
              </tr>
              <tr>
                <td>14:00-14:40</td>
                <td>Why do we need NeuroAI?</td>
                <td>CAO Lihong</td>
                <td>Professor at the Neuroscience and Intelligent Media Institute, Communication University of China</td>
              </tr>
              <tr>
                <td>14:40-15:20</td>
                <td>Low-Power Neuromorphic Chips and Systems for AIoT Applications</td>
                <td>ZOU Zhuo</td>
                <td>Professor at Fudan University</td>
              </tr>
              <tr>
                <td>15:20-16:00</td>
                <td>Development and Applications of Neuromorphic Sensing and Computing</td>
                <td>QIAO Ning</td>
                <td>Founder and CEO of SynSense</td>
              </tr>
              <tr>
                <td>16:00-16:40</td>
                <td>Towards Next-Generation Auditory Information Processing: A Neuromorphic Approach</td>
                <td>WU Jibin</td>
                <td>Assistant Professor in the Department of Computing, Hong Kong Polytechnic University</td>
              </tr>
            </table>

 
            <br></br>
            <br></br>
            <br></br>
            <h1 align="center" style={{ color: "red" }}>Schedule (July 23, 2023)</h1>
            <h3>KeyNote:09:00-11:40</h3>


            <table border='1'>
              <tr>
                <td colspan='3' bgcolor='gray'>Keynote</td>
              </tr>
              <tr>
              <td colspan='3'>Host : Zhou Bowen 
Tsinghua Scholar Chair Professor, Tenured Professor in the Department of Electronics, Founder of Jieyuan Technology, IEEE/CAAI Fellow
</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>Topic</td>
                <td>Speaker</td>
              </tr>
              <tr>
                <td>09:00-09:40</td>
                <td>From Remote Sensing Big Data to Remote Sensing Big Models</td>
                <td>Zhou Chenghu
Academician of the Chinese Academy of Sciences,  Academician of the International Eurasian Academy of Sciences,  Researcher at the Institute of Geographic Sciences and Natural Resources Research,  Chinese Academy of Sciences, Co-founder of the Deep-Time Digital Earth Program
</td>
              </tr>
              <tr>
                <td>09:40-10:20</td>
                <td>Rehearsal: From Prediction to Decision</td>
                <td>Zhou Zhihua
Vice President of the CAAI, Fellow of the European Academy of Sciences, Chair of the Department of Computer Science/Director of the School of Artificial Intelligence at Nanjing University, CCW/AAGT/TEEE/CAA Fellow
</td>
              </tr>
              <tr>
                <td colspan='3'>Host: Yu Yuanlong
Deputy Director of the Cognitive Systems and Information Processing Committee at CAAI, Dean and Professor of the School of Computer and Big Data at Fuzhou University.
</td>
              </tr>
              <tr>
                <td>10:20-11:00</td>
                <td>Intelligent Monitoring and Control of Interconnected Cyber-Physical Systems</td>
                <td>Marios M.Polycarpou
Academician of the Cyprus Academy of Sciences/Literature and Arts,  Director of the KIOS Research and Innovation Center of Excellence, Professor at the University of Cyprus,  EE/FACFellow
</td>
              </tr>
              <tr>
                <td>11:00-11:40</td>
                <td>Exploration and Practice of the Integration and Development of Artificial Intelligence and Pharmaceutical Industry</td>
                <td> Liu Xuesong
Academician of Russian Academy of Engineering, Distinguished Professor and Researcher of Zhejiang University Qiu Shi.
</td>
              </tr>
            </table>


            <h3>Oral Session:13:00-15:20</h3>

            <table border='1'>
              <tr>
                <td colspan='4' bgcolor='gray'>Oral Session 1</td>
                
                
              </tr>
              <tr>
                <td colspan='4'>Oral Session Chair: Wang Ruiping
Reasearcher at the Institute of Computing Technology, Chinese Academy of Sciences
</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>Paper ID</td>
                <td>Paper Title</td>
                <td>Authors</td>
              </tr>
              <tr>
                <td>13:00-13:10</td>
                <td>12</td>
                <td>MARS: An Instance-aware, Modular and Realistic Simulator for Autonomous Driving</td>
                <td>Wu, Zirui; Liu, Tianyu;
Luo, Liyi; Zhong, Zhide;
Chen, Jianteng;
Xiao, Hongmin;Hou, Chao;
Lou, Haozhe; Chen, Yuantao; 
Yang, Runyi; Huang, Yuxin;
Ye, Xiaoyu; Yan, Zike;
Shi, yongliang; Liao, Yiyi;
Zhao, Hao</td>
              </tr>
              <tr>
                <td>13:10-13:20</td>
                <td>109</td>
                <td>Explicit Composition of Neural Radiance Fields by Learning an Occlusion Field</td>
                <td>Sun, Xusen; Zhu, Hao; Lu, Yuanxun; Cao, Xun</td>
              </tr>
              <tr>
                <td>13:20-13:30</td>
                <td>361</td>
                <td>SPCTNet: A Series-Parallel 
CNN and Transformer 
Network for 3D Medical Image 
Segmentation</td>
                <td>Yu, Bin; Zhou, Quan;
Zhang, Xuming</td>
              </tr>
             
              <tr>
                <td>13:30-13:40</td>
                <td>429</td>
                <td>Visible and NIR Image Fusion Algorithm Based on Information Complementarity</td>
                <td>Li, Zhuo; Li, Bo</td>
              </tr>

            </table>

            <br></br>
            <table border='1'>
              <tr>
                <td colspan='4' bgcolor='gray'>Oral Session 2 </td>
              </tr>
              <tr>
                <td colspan='4'>Oral Session Chair :Chen Xun
Deputy Director of Committee on Brain-Machine Integration and Bio-Machine Intelligence at CAAI
</td>
              </tr>
              <tr>
                <td >Time</td>
                <td>Paper ID</td>
                <td>Paper Title</td>
                <td>Authors</td>
              </tr>
              <tr>
                <td >13:50-14:00</td>
                <td>116</td>
                <td>End-to-End Optimization of Quantization-based Structure Learning and Interventional Next-item Recommendation</td>
                <td>Fu, Kairui; Miao, Qiaowei; Zhang, Shengyu; Kuang, Kun; Wu, Fei</td>
              </tr>
              <tr>
                <td >14:00-14:10c</td>
                <td>139</td>
                <td>Energy-based Policy Constraint for Offline Reinforcement Learning</td>
                <td>Peng, Zhiyong; Han, Changlin; Liu, Yadong; Zhou, Zongtan</td>
              </tr>
              <tr>
                <td >14:10-14:20</td>
                <td>172</td>
                <td>Integrating Human Parsing and Pose Network for Human Action Recognition</td>
                <td>Ding, Runwei; Wen, Yuhang; Liu, Jinfu; Dai, Nan; Meng, Fanyang; Liu, Mengyuan</td>
              </tr>
              <tr>
                <td >14:20-14:30</td>
                <td>185</td>
                <td>An Efficient Graph Transformer Network for Video-based Human Mesh Reconstruction</td>
                <td>Tang, Tao; You, Yingxuan; Wang, Ti; Liu, Hong</td>
              </tr>

              <tr>
                <td >14:30-14:40</td>
                <td>404</td>
                <td>Structural Recognition of Handwritten Chinese Characters Using a Modified Part Capsule Auto-Encoder</td>
                <td>Wu, Xin-Jian; Ao, Xiang; Zhang, Ruisong; Liu, ChengLin</td>
              </tr>
            </table>
            <br></br>
            <table border='1'>
              <tr>
                <td colspan='4' bgcolor='gray'>Oral Session 3</td>
              </tr>
              <tr>
                <td colspan='4'>Oral Session Chair: Ji Mengqi
Associate Professor of Beihang Unviersity
</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>Paper ID</td>
                <td>Paper Title</td>
                <td>Authors</td>
              </tr>
              <tr>
                <td>14:40-14:50</td>
                <td>150</td>
                <td>Multi-Round Dialogue State Tracking by Object-Entity Alignment in Visual Dialog</td>
                <td>Pang, Wei</td>
              </tr>
              <tr>
                <td>14:50-15:00</td>
                <td>156</td>
                <td>Weakly-Supervised Grounding for VQA with Dual Visual-Linguistic Interaction</td>
                <td>Liu, Yi; Pan, Junwen; Wang, Qilong; Zhu, Pengfei; Chen, Guanlin; Nie, Weiguo; Zhang, Yudong; Gao, Qian; Hu, Qinghua</td>
              </tr>
              <tr>
                <td>15:00-15:10</td>
                <td>268</td>
                <td>Diagnosis then Aggregation: An Adaptive Ensemble strategy for Keyphrase Extraction</td>
                <td>Jin, Xin; Liu, Qi; Yue, Linan; Liu, Ye; Zhao, Lili; Gao, Weibo; Gong, Zheng; Zhang, Kai; Bi, Haoyang</td>
              </tr>
              <tr>
                <td>15:10-15:20</td>
                <td>273</td>
                <td>Text-oriented Modality Reinforcement Network for Multimodal Sentiment Analysis from Unaligned Multimodal Sequences</td>
                <td>Lei, Yuxuan; Yang, Dingkang; Li, Mingcheng; Wang, Shunli; Chen, Jiawei; Zhang, Lihua</td>
              </tr>
             
            </table>


            <h3>Poster Exhibition:09:00-17:00 </h3>
            <h3>Demo Exhibition:09:00-17:00</h3>
            <h3>Conference Awards and Ending:17:00-17:30 </h3>



            {/* <table>
              <tr>
                <td colspan='3' >Poster Exhibition</td>
              </tr>
              <tr>
                <td>NO</td>
                <td>Paper ID</td>
                <td>Paper Title</td>
              </tr>
              <tr>
                <td>1</td>
                <td>116</td>
                <td>End-to-End Optimization of Quantization-based Structure Learning and Interventional Next-item Recommendation</td>
              </tr>
              <tr>
                <td>2</td>
                <td>429</td>
                <td>Visible and NIR Image Fusion Algorithm Based on Information Complementarity</td>
              </tr>
              <tr>
                <td>3</td>
                <td>64</td>
                <td>Dual-Domain Network for Restoring  Images from Under-Display Cameras</td>
              </tr>
              <tr>
                <td>4</td>
                <td>74</td>
                <td>Model Distillation for Lane Detection on Car-level Chips</td>
              </tr>
              <tr>
                <td>5</td>
                <td>150</td>
                <td>Multi-Round Dialogue State Tracking by Object-Entity Alignment in Visual Dialog</td>
              </tr>
              <tr>
                <td>6</td>
                <td>341</td>
                <td>Personalizing Intervened Network for Long-tailed Sequential User Behavior Modeling</td>
              </tr>
              <tr>
                <td>7</td>
                <td>390</td>
                <td>Heterogeneous Link prediction via Mutual Information Maximization between Node Pairs</td>
              </tr>
              <tr>
                <td>8</td>
                <td>404</td>
                <td>Structural Recognition of Handwritten Chinese Characters Using a Modified Part Capsule Auto-Encoder</td>
              </tr>
              <tr>
                <td>9</td>
                <td>3</td>
                <td>3
Sequential Style Consistency Learning for Domain-Generalizable Text Recognition
</td>
              </tr>
              <tr>
                <td>10</td>
                <td>11</td>
                <td>How to Select the Appropriate One From the Trained Models for Model-Based OPE</td>
              </tr>
              <tr>
                <td>11</td>
                <td>61</td>
                <td>MusicGAIL: A Generative Adversarial Imitation Learning Approach for Music Generation</td>
              </tr>
              <tr>
                <td>12</td>
                <td>98</td>
                <td>Domain Incremental Learning for EEG-Based Seizure Prediction</td>
              </tr>
              <tr>
                <td>13</td>
                <td>109</td>
                <td>Explicit Composition of Neural Radiance Fields by Learning an Occlusion Field</td>
              </tr>
              <tr>
                <td>14</td>
                <td>122</td>
                <td>Fast Hierarchical Depth Super-Resolution via Guided Attention</td>
              </tr>
              <tr>
                <td>15</td>
                <td>162</td>
                <td>LTUNet: A Lightweight Transformer-based UNet with multi-scale mechanism for Skin Lesion Segmentation</td>
              </tr>
              <tr>
                <td>16</td>
                <td>168</td>
                <td>STU3 : Multi-organ CT medical image segmentation model based on Transformer and Unet</td>
              </tr>
              <tr>
                <td>17</td>
                <td>273</td>
                <td>Text-oriented Modality Reinforcement Network for Multimodal Sentiment Analysis from Unaligned Multimodal Sequences</td>
              </tr>
              <tr>
                <td>18</td>
                <td>350</td>
                <td>Weakly Supervised Optical Remote Sensing Salient Object Detection Based on Adaptive Discriminative Region Suppression</td>
              </tr>
              <tr>
                <td>19</td>
                <td>361</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table> */}

            
        </div>
        )


};

export default Technical;