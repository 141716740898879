import React from 'react';
import { useTable } from 'react-table';
import styles from './Speaker.module.scss';
import speaker_1 from '../imgs/tutorial/sp1.jpg';
import speaker_2 from '../imgs/tutorial/sp2.jpg';
import speaker_3 from '../imgs/tutorial/sp3.jpg';
import speaker_4 from '../imgs/tutorial/sp4.jpg';
import speaker_5 from '../imgs/tutorial/sp5.jpg';
import styled from 'styled-components'


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 0px solid black;
    tr {
    :nth-child(even)
        {background: #F5F5F5
        }
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-family: Georgia, serif;
      :last-child {
        border-right: 0;
      }
      
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}



const Tutorial = ()=> {
    const columns = React.useMemo(
        () => [
          {
            Header: 'Tutorials for AI Digital Avatar track',
            columns: [
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
            ],
          },
        ],
        []
      )
    
    const data1 = React.useMemo(() =>
     [
     {
     Speaker: 'Yebin Liu',
     Title: 'Towards AI Avatar',
     },
     {
     Speaker: 'Xiaoguang Han',
     Title: 'Learning to Reconstruct 3D Garment from Single Images',
     },
     {
        Speaker: 'Juyong Zhang',
        Title: 'Monocular Camera based Human Modeling and Animation',
    },
    {
        Speaker: 'Xiaowei Zhou',
        Title: 'Human Performance Capture with Few RGB Cameras',
    },
    {
        Speaker: 'Siyu Tang',
        Title: 'Inhabiting the virtual',
    },    
     ],
     []
    )
   
    
    const columns1 = React.useMemo(
        () => [
          {
            Header: 'Tutorials for Graph-based machine learning track',
            columns: [
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
            ],
          },
        ],
        []
      )

    const data2 = React.useMemo(() =>
     [
     {
     Speaker: 'Renjie Liao',
     Title: 'Generalization bound of GNNs',
     },
     {
     Speaker: 'Dong Tian',
     Title: 'Graph: Its Concept and Application in Point Cloud Processing',
     },
     {
        Speaker: 'Siheng Chen',
        Title: 'Graph learning for Collective Intelligence',
    },
    {
        Speaker: 'Chuan Shi',
        Title: 'Recent Development of Heterogeneous Graph Neural Networks',
    },
    {
        Speaker: 'Yue Gao',
        Title: 'Hypergraph Computing: Method and Practice',
    },    
     ],
     []
    )

    const columns2 = React.useMemo(
        () => [
          {
            Header: 'Tutorials for Intelligence in Natural Language Understanding and Processing track',
            columns: [
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
            ],
          },
        ],
        []
      )

    const data3 = React.useMemo(() =>
     [
     {
     Speaker: 'Xipeng Qiu',
     Title: 'Towards Efficient Natural Language Processing',
     },
     {
     Speaker: 'Junlan Feng',
     Title: 'The Human-Machine Dialog Technology: The companion Journey in the Industry and Academia',
     },
     {
        Speaker: 'Hongning Wang',
        Title: 'Comparative Explanation of Recommendations',
    },
     {
        Speaker: 'Nanyun Peng',
        Title: 'Controllable Text Generation Beyond Auto-Regressive Models',
    }, 
     ],
     []
    )

    const columns3 = React.useMemo(
        () => [
          {
            Header: 'Tutorials for Human-Robot Interaction for Dexterous Manipulation track',
            columns: [
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
            ],
          },
        ],
        []
      )

    const data4 = React.useMemo(() =>
     [
     {
     Speaker: 'Bin Fang',
     Title: 'Human-Robot Interaction for Dexterous Manipulation',
     },
     {
     Speaker: 'Yuntao Wang',
     Title: 'Physiological Sensing Techniques on Modern Smart Devices',
     },
     {
        Speaker: 'Yun Huang',
        Title: 'Designing Conversational AI for Human-Human Interaction',
    },
    {
      Speaker: 'Yiwen Wang',
      Title: 'Brain Machine Interfaces—the Era of Avatar',
    },
     ],
     []
    )

    const columns4 = React.useMemo(
        () => [
          {
            Header: 'Tutorials for Art and Artificial Intelligence track',
            columns: [
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
            ],
          },
        ],
        []
      )

    const data5 = React.useMemo(() =>
     [
     {
     Speaker: 'Weiming Dong',
     Title: 'Image Style Transfer',
     },
     {
     Speaker: 'Duan Zhiyao',
     Title: 'A Brief History of Interactive Music Systems',
     },
     {
        Speaker: 'Yun Wang',
        Title: 'Responsible AI and Design Innovation',
    },
    {
        Speaker: 'Di Lu',
        Title: 'Towards the Implementation of an Automatic Composition System for Pop Songs',
    },
     ],
     []
    )



    return (
    <div>
        <h1>Tutorial</h1>
        <h2>AI Digital Avatar</h2>
        <p>Digital human avatar will serve as the future interface for human-AI interaction and holographic communication. It is also the core infrastructure for metaverse. Focusing on the key and cutting-edge components of AI digital human avatar, this tuturial will introduce 3D representation, motion capture, 3D reconstruction, neural rendering and multimodal intelligence and of AI digital human. This tutorial invites top scholars from Tsinghua University, Zhejiang University, University of Science and Technology of China, Chinese University of Hong Kong (Shenzhen), and ETH Zürich to introduce the topics of AI human avatar, from motion capture, 3D reconstruction, neural rendering to intelligent Avatar.</p>
        <Styles>
        <Table columns={columns} data={data1} />
        </Styles>

        <h2>Graph-based machine learning</h2>
        <p>Graph-based machine learning extends classic convolutional neural networks to the non-Euclidean domain, which has a wide range of applications in various fields, including brain science, network analysis, computer vision, computer graphics, physics, chemistry, medical care, etc. This tutorial invites top scholars from the University of British Columbia, InterDigital, Tsinghua University, Shanghai Jiaotong University, and Beijing University of Posts and Telecommunications, to introduce graph-based machine learning from basic theory to cutting-edge applications.</p>
        <Styles>
        <Table columns={columns1} data={data2} />
        </Styles>        

        <h2>Intelligence in Natural Language Understanding and Processing</h2>
        <p>Natural language processing is one of the core components in the blueprint of artificial intelligence technology. Along with the fast development of natural language processing, people have promising expectations with respect to communication and interaction with machines through natural languages. This tutorial introduces the research frontier of artificial intelligence in natural language processing. We have invited scholars from Fudan University, China Mobile Research, University of Virginia, and University of California Los Angeles to introduce the modern research and key techniques in natural language processing. The speakers will envision the theories as well as practice of real applications and development plans of artificial intelligence in natural languages.</p>

        <Styles>
        <Table columns={columns2} data={data3} />
        </Styles>       

        <h2>Human Computer Interaction and Robotics</h2>
        <p>This tutorial focuses on the information exchange process between humans and end-user devices, robots, and intelligent agents. With the rapid development of novel interaction scenarios including metaverse and pervasive computing, it has produced a wide range of influences and changes on intelligent medical treatment, wearable computing, human-machine collaboration and intelligent interaction, and is a research hotspot with both academic and application frontier characteristics. This tutorial invites top scholars from UIUC and Tsinghua University, to introduce the topics of smart sensing, human-robot interaction and human-oriented AI dialogue technologies, from basic theory, research methods to cutting-edge applications.</p>

        <Styles>
        <Table columns={columns3} data={data4} />
        </Styles>       

        <h2>Art and Artificial Intelligence</h2>
        <p>The core of art and artificial intelligence is the computer's "creativity" cultivation, which assumes that the computer is the subject - artist for artistic creation. Artistic creation activities reflect the unique complexity and creativity of human beings, using machines to assist or independently carry out artistic creation as a challenge of milestones in the field of art and artificial intelligence. This topic Tutorial invites scholars from the University of Rochester, Central Conservatory of Music, Tsinghua University, Beihang University, Chinese Academy of Sciences, from artistic field artificial intelligence theory to the application, introduce computer Technology in music, design, brain science, and expands on computer in creativity.</p>

        <Styles>
        <Table columns={columns4} data={data5} />
        </Styles>       

    </div>
    )
};

export default Tutorial;

