import React from 'react';
import { Link } from 'react-router-dom';
// import styles from './content.module.scss';

const Home = ()=> (
    <div class='home'>
        {/* <h3>NEWS</h3>
        <p>Thank you for submitting your paper to CICAI 2022. We have successfully wrapped up the decision process with the tremendous effort of our reviewers and area chairs. <b> The list of paper IDs of accepted papers is attached in <a href={process.env.PUBLIC_URL + '/Accepted Paper ID.txt'} target="_blank">Accepted Paper ID </a></b>.
        We hope to see you in Beijing! </p>
        <p>The schedule can be derived in the <a href={process.env.PUBLIC_URL + '/cicai_schedule.pdf'} target="_blank">schedule document.</a> </p> */}

        <p><br></br></p>
        <p>The papers accepted for CICAI23 have now been published online by Springer. Access them here:<br></br>
            <p>Part I : <a href='https://link.springer.com/book/10.1007/978-981-99-8850-1'>https://link.springer.com/book/10.1007/978-981-99-8850-1</a><br></br></p>
            <p>Part II: <a href='https://link.springer.com/book/10.1007/978-981-99-9119-8'> https://link.springer.com/book/10.1007/978-981-99-9119-8</a></p>
        </p>
        <hr></hr>
        <p> The CAAI International Conference on Artificial Intelligence(CICAI 2023) will be held at Fuzhou, China on July 22nd-23rd. CICAI is organized by Chinese Association for Artificial Intelligence (CAAI). The aim of CICAI is to promote advanced research in AI, and foster scientific exchange between researchers, practitioners, scientists, students, and engineers in AI and its affiliated disciplines. </p>
        <p> The program committee of CICAI 2023 invites the submission of papers for the technical program of the conference. High-quality original submissions are welcome from research results and applications of all areas of AI including but not limited to the following areas: </p>
        {/* <ul>
            <li>Brain Inspired AI</li>
            <li>Optimization</li>
            <li>Machine Learning</li>
            <li>Multi-agent Systems</li>
            <li>Computer Vision</li>
            <li>Humans and AI</li>
            <li>Natural Language Processing</li>
            <li>AI Ethics, Privacy, Fairness and Security</li>
            <li>Knowledge Representation and Reasoning</li>
            <li>Explainability, Understandability, and Verifiability of AI</li>
            <li>Data Mining</li>
            <li>Multidisciplinary Research with AI</li>
            <li>Robotics</li>
            <li>Applications of Artificial Intelligence</li>
            <li>AI Ethics, Privacy, Fairness and Security</li>
            <li>Other AI related topics</li>
        </ul> */}
        <ul>
            <li>AI Ethics,Privacy, Fairness and Security</li>
            <li>Al for Education</li>
            <li>AI Generated Content</li>
            <li>Applications of Artificial Intelligence</li>
            <li>Brain Inspired Al</li>
            <li>Computer Vision</li>
            <li>Data Mining</li>
            <li>Explainability, Understandability, and Verifiability of Al</li>
            <li>Granular Computing</li>
            <li>Humans and Al</li>
            <li>Knowledge Representation and Reasoning</li>
            <li>Large Models</li>
            <li>Machine Learning</li>
            <li>Metaverse</li>
            <li>Multi-agent Systems</li>
            <li>Multidisciplinary Research with AI</li>
            <li>Natural Language Processing</li>
            <li>Optimization</li>
            <li>Robotics</li>
            <li>Other Al related topics</li>
        </ul>

        
    
    </div>
);

export default Home;