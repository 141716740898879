import React from 'react';







const Challenges = ()=> {
   
    return (
    <div>
        <h1>Challenges</h1>

        Challenges will start on April 10th. Please visit <a href="https://gigavision.cn/cicai">https://gigavision.cn/</a> for more details. 

        <h2>GigaDetection</h2>
        <p>Object detection is a vision task centered on people or objects, and it is a key problem in computer vision. Its applications are very extensive, including pedestrian behavior analysis, intelligent transportation, automatic driving, intelligent security and other fields. In the past few years, many advances in the field of object detection have been driven by some challenging public data sets.
In order to maintain the further technical innovation in the field of object detection, this challenge provides a pedestrian and vehicle dataset of giga-pixel images. The extremely high resolution makes it possible to detect pedestrians from a long distance, and gives the contestants great freedom to choose solutions solving the problem that the current detection algorithms performance reductions due to significant differences in scale, posture and occlusion. We aim to obtain breakthrough achievements by exploring the object detection task based on giga-pixel images.</p>

        {/* <h2>GigaMOT</h2>
        <p>Multiple Object Tracking (MOT) is a key technology in the field of computer vision, which is widely used in automatic driving, intelligent monitoring, and human behavior recognition. MOT task detects people, vehicles, and objects in continuous frames or videos, gives ID information and tracks positions. Its inputs are continuous frames or a video, and its outputs are the tracking results of multiple targets, including the bounding box and the corresponding ID information. Multiple object tracking tasks should not only solve the difficulties occurred in single object tracking, such as mutual occlusion, motion blur, crowded scenes, and scale changes, but also deal with the start and end of object tracks, mutual interference between similar objects, etc.
In recent years, researchers have released some MOT datasets, including MOT Challenge datasets, KITTI, etc. Compared with traditional datasets, PANDA-Video dataset provided by this challenge is a novel large-scale dataset, which is naturally suitable for long-term multiple object tracking due to its large visual range. Moreover, the crowded and complicated pedestrian scenes also bring new challenges to the MOT algorithms on the traditional dataset.</p>
 */}

        <h2>GigaTrajectory</h2>
        <p>When people walk in crowded public places such as sidewalks, airport terminals or shopping centers, they will abide by common sense rules. For example, when people consider where to go next, they will protect their personal space and make way. The establishment of pedestrian trajectory prediction model to understand and predict human motion in complex real environment has extensive application value, such as for intelligent robot, automatic driving and intelligent transportation. One of the difficulties of trajectory prediction is to understand the complex and often subtle interactions between people in a crowded space.
The dataset provided by this challenge has a large-scale view and free movement space. Moreover, some complex and crowded pedestrians scenes also bring new challenges to the trajectory prediction algorithms on traditional datasets.</p>

        <h2>GigaReconstruction</h2>
        <p>3D reconstruction is an important research topic in the field of computer vision, and has been widely used in games, movies, autonomous driving, VR/AR, and industrial manufacturing. Multiview stereopsis (MVS) methods, which can reconstruct both the 3D geometry and texture from multiple images, have been rapidly developed and extensively investigated from the feature engineering methods to the data-driven ones.
3D scene datasets are available, such as indoor RGBD dataset ScanNet and outdoor street dataset KITTI. However, there is no dataset containing both the 3D geometry of large-scale scenes and high-resolution observations of small details to benchmark the algorithms. For this reason, we provide the first benchmark dataset GigaMVS, for 3D reconstruction of large scale scenes based on giga-pixel images.giga-pixel images with wide field of view and high resolution details allow to clearly observe the overall scene structure of buildings and local details of small objects at the same time. The geometric information's true value (Ground Truth) is obtained by the laser scanner, which contains several very large scenes with an average area of 8,667 square meters and the largest scene with an area of 32,007 square meters.
Due to the extremely large scale, complex occlusions and giga-pixel images, GigaMVS challenges the accuracy and efficiency of existing MVS algorithms, pointing out some shortcomings of existing methods and prospects for future work. We believe that GigaMVS can benefit the community of 3D reconstruction and support the development of novel algorithms balancing robustness, scalability and accuracy.</p>

        {/* <h2>GigaCrowd</h2>
        <p>Crowd analysis has important applications in social security and public health, and has received extensive attention from computer vision and sociology. Accurate 3D human body pose estimation and relative position estimation can directly provide social distance, human body orientation information, which combined with specific pose will help human and crowd movement, activity and behavior analysis. Also with the proposed metaverse, 3D human reconstruction is its key role. The difficulties in recovering 3D human models directly from RGB images or videos are the complexity of the human body, occlusion and spatial ambiguity of 2D inferred 3D poses, etc.
The GigaCrowd dataset provided in this track has the characteristics of large scenes, dense crowds and at the same time undistorted and rich in details compared with the current human body image datasets. This track presents a challenge to current human reconstruction methods. We hope that based on the GigaCrowd dataset, the parameterists can further innovate the human body reconstruction techniques, not limited by the image size, and improve the scalability of the methods in this field, which can promote the progress of metaverse technology and provide a springboard for more complex semantic understanding.</p>
        */}

        <h2>Challenge Chairs</h2>
        <ul>
            <li>David Brady (University of Arizona) </li> 
        </ul>
        <ul>
            <li>Haozhe Lin (Tsinghua University) </li> 
        </ul>
        <p>For any question, please contact the challenge chairs <br/>
        (<a href="mailto:linhz@mail.tsinghua.edu.cn">linhz@mail.tsinghua.edu.cn</a>&nbsp;, &nbsp;<a href="mailto:challenge@gigavision.cn">challenge@gigavision.cn</a>)
        </p>
    </div>
    )
};

export default Challenges;

