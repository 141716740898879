import React from 'react';
import styled from 'styled-components'
import { useTable } from 'react-table';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 0px solid black;
    tr {
    :nth-child(even)
        {background: #F5F5F5
        }
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-family: Georgia, serif;
      :last-child {
        border-right: 0;
      }
      
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Technical = ()=> {

    const columns = React.useMemo(
        () => [
          {
            Header: '@ Zhongwu Hall, Room 105, Yanxi Hotel Conference Center ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data1 = React.useMemo(() =>
     [
     {
     Time: '09:00-09:35',
     Speaker: 'Renjie Liao, University of British Columbia',
     Title: 'Generalization bound of GNNs',
     },
     {
    Time:'09:35-10:10',
     Speaker: 'Dong Tian, InterDigital',
     Title: 'Graph: Its Concept and Application in Point Cloud Processing',
     },
     {
        Time:'10:10-10:45',
         Speaker: 'Siheng Chen, Shanghai Jiao Tong University',
         Title: 'Graph Learning for Collective Intelligence',
    },
    {
        Time:'10:45-11:20',
         Speaker: 'Chuan Shi, Beijing University of Posts and Telecommunications',
         Title: 'Recent Development of Heterogeneous Graph Neural Networks',
    },
    {
        Time:'11:20-11:55',
         Speaker: 'Yue Gao, Tsinghua University',
         Title: 'Hypergraph Computing: Method and Practice',
    },
     ],
     []
    )

    const columns1 = React.useMemo(
        () => [
          {
            Header: '@ Meeting Room E, Room 105, Yanxi Hotel',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data2 = React.useMemo(() =>
     [
     {
     Time: '09:00-09:30',
     Speaker: 'Bin Fang, Tsinghua University',
     Title: 'Human-Robot Interaction for Dexterous Manipulation',
     },
     {
     Time:'09:30-10:00',
     Speaker: 'Yiwen Wang, Hong Kong University of Science and Technology',
     Title: 'Brain Machine Interfaces—the Era of Avatar',
     },
     {
        Time:'10:00-10:30',
         Speaker: 'Yuntao Wang, Tsinghua University',
         Title: 'Physiological Sensing Techniques on Modern Smart Devices',
    },
    {
        Time:'10:30-11:00',
         Speaker: 'Yun Huang, University of Illinois at Urbana-Champaign',
         Title: 'Designing Conversational AI for Human-Human Interaction',
    },
     ],
     []
    )

    const columns2 = React.useMemo(
        () => [
          {
            Header: '@ Zhaohua Hall, Yanxi Hotel Conference Center',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data3 = React.useMemo(() =>
     [
     {
     Time: '09:00-09:30',
     Speaker: 'Weiming Dong, Institution of Automation, CAS',
     Title: 'Image Style Transfer',
     },
     {
     Time:'09:30-10:00',
     Speaker: 'Zhiyao Duan, University of Rochester',
     Title: 'A Brief History of Interactive Music Systems',
     },
     {
        Time:'10:00-10:30',
         Speaker: 'Yun Wang, Beihang University',
         Title: 'Responsible AI and Design Innovation',
    },
    {
        Time:'10:30-11:00',
         Speaker: 'Di Lu, Central Conservatory of Music',
         Title: 'Towards the Implementation of an Automatic Composition System for Pop Songs',
    },
     ],
     []
    )


    const columns3 = React.useMemo(
        () => [
          {
            Header: '@ Meeting Room E, Room 105, Yanxi Hotel',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data4 = React.useMemo(() =>
     [
     {
     Time: '13:30-14:00',
     Speaker: 'Hongning Wang, University of Virginia',
     Title: 'Comparative Explanation of Recommendations',
     },
     {
     Time:'14:00-14:30',
     Speaker: 'Junlan Feng, China Mobile',
     Title: 'The Human-Machine Dialog Technology: The Companion Journey in the Industry and Academia ',
     },
     {
        Time:'14:30-15:00',
         Speaker: 'Xipeng Qiu, Fudan University',
         Title: 'Towards Efficient Natural Language Processing',
    },
    {
        Time:'15:00-15:30',
         Speaker: 'Nanyun Peng, University of California Los Angeles',
         Title: 'Controllable Text Generation Beyond Auto-Regressive Models',
    },
     ],
     []
    )

    const columns4 = React.useMemo(
        () => [
          {
            Header: '@ Zhongwu Hall, Room 105, Yanxi Hotel Conference Center',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Speaker',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data5 = React.useMemo(() =>
     [
     {
     Time: '13:30-14:05',
     Speaker: 'Xiaowei Zhou, Zhejiang University',
     Title: 'Human Performance Capture with Few RGB Cameras',
     },
     {
     Time:'14:05-14:40',
     Speaker: 'Juyong Zhang, University of Science and Technology of China',
     Title: 'Monocular Camera based Human Modeling and Animation',
     },
     {
        Time:'14:40-15:15',
         Speaker: 'Xiaoguang Han, The Chinese University of Hong Kong',
         Title: 'Learning to Reconstruct 3D Garment from Single Images',
    },
    {
        Time:'15:15-15:50',
         Speaker: 'Yebin Liu, Tsinghua University',
         Title: 'Towards AI Avatar',
    },
    {
        Time:'15:50-16:25',
         Speaker: 'Siyu Tang, ETH Zurich',
         Title: 'Inhabiting the virtual',
    },
     ],
     []
    )

    const columns5 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data6 = React.useMemo(() =>
     [
     {
     Time: '09:50-10:00',
     Speaker: 'Weilin Sun, Xiangxian Li,Yuqing Wang, Yuze Zheng,Xiangxu Meng, Lei Meng',
     Title: 'Sequential Fusion of Multi-view Video Frames for 3D Scene Generation',
     },
     {
     Time:'10:00-10:10',
     Speaker: 'Haibiao Xuan,Jinsong Zhang, Kun Li',
     Title: 'MHPro: Multi-Hypothesis Probabilistic Modeling for Human Mesh Recovery',
     },
     {
        Time:'10:10-10:20',
         Speaker: 'Ming Guo, Shunfei Wang,Zhibo Wang, Ming Lu,Xiufen Cui, Xiao Ling,Feng Xu',
         Title: '3D Face Cartoonizer: Generating Personalized 3D Cartoon Faces from 2D Real Photos with a Hybrid Dataset',
    },
    {
        Time:'10:20-10:30',
         Speaker: 'Yanli Liu, Bochen Guan,Weiyi Li, Qinwen Xu,Shuxue Quan',
         Title: 'SMOF: Squeezing More Out of Filters Yields Hardware-Friendly CNN Pruning',
    },
    {
        Time:'10:30-10:40',
         Speaker: 'Yaping Zhao, Haitian Zheng,Mengqi Ji, Ruqi Huang',
         Title: 'Cross-Camera Deep Colorization',
    },
     ],
     []
    )

    const columns6 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data7 = React.useMemo(() =>
     [
     {
     Time: '10:50-11:00',
     Speaker: 'Fenyu Hu, Zeyu Cui,Shu Wu, Qiang Liu,Jinlin Wu, Liang Wang,Tieniu Tan',
     Title: 'Second-order Global Attention Networks for Graph Classification and Regression',
     },
     {
     Time:'11:00-11:10',
     Speaker: 'Kaifang Wu, Yonghui Yang,Kun Zhang, Le Wu,Jing Liu, Xin Li',
     Title: 'Multi-Relational Cognitive Diagnosis for Intelligent Education',
     },
     {
        Time:'11:10-11:20',
         Speaker: 'Jun Wang, Qianlong Wang,Zhiyuan Wen, Ruifeng Xu',
         Title: 'Interactive Fusion Network with Recurrent Attention for Multimodal Aspect-based Sentiment Analysis',
    },
    {
        Time:'11:20-11:30',
         Speaker: 'Huimei Tang, Zengyang Shao, Yutao Zhang, Lijia Ma,Qiuzhen Lin',
         Title: 'Dynamic Network Embedding by Using Sparse Deep Autoencoder',
    },
    {
        Time:'11:30-11:40',
         Speaker: 'Rui Ji; Yibing Zhan,Rong Zhang',
         Title: 'Deep Graph Convolutional Networks Based on Contrastive Learning: Alleviating Over-smoothing Phenomenon',
    },
     ],
     []
    )

    const columns7 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data8 = React.useMemo(() =>
     [
     {
     Time: '14:10-14:20',
     Speaker: 'Aihua Yin, Weiwen Lu,Sidong Wang, Hongzhi You,Ru-Yuan Zhang, Da-Hui Wang, Zonglei Zhen,Xiaohong Wan',
     Title: 'Visual Perception Inference on Raven’s Progressive Matrices by Semi-Supervised Contrastive Learning',
     },
     {
     Time:'14:20-14:30',
     Speaker: 'Zhengyin Chen, Kun Liu,Wenpin Jiao',
     Title: 'A Genetic Algorithm for Causal Discovery Based on Structural Causal Model',
     },
     {
        Time:'14:30-14:40',
         Speaker: 'Zaipeng Xie, Cheng Ji,Yufeng Zhang',
         Title: 'Deep Skill Chaining with Diversity for Multi-agent Systems',
    },
    {
        Time:'14:40-14:50',
         Speaker: 'Jiyuan Zheng, Shaoliang Yu,Jiamin Wu, Yuyan Wang,Chenchen Deng, Zhu Lin',
         Title: 'A Novel In-sensor Computing Architecture based on Single Photon Avalanche Diode and Dynamic Memristor',
    },
     ],
     []
    )

    const columns8 = React.useMemo(
        () => [
          {
            Header: ' ',
            columns: [
              {
                Header: 'Time',
                accessor: 'Time',
              },
              {
                Header: 'Title',
                accessor: 'Title',
              },
              {
                Header: 'Authors',
                accessor: 'Speaker',
              },
            ],
          },
        ],
        []
      )
    
    const data9 = React.useMemo(() =>
     [
     {
     Time: '15:00-15:10',
     Speaker: 'Tianqi Zhao, Ming Kong,Kun Kuang, Zhengxing Huang, Qiang Zhu,Fei Wu',
     Title: 'Connecting Patients with Pre-diagnosis: A Multiple Graph Regularized Method for Mental Disorder Diagnosis',
     },
     {
     Time:'15:10-15:20',
     Speaker: 'Yuxin Su, Ziling Miao, Hong Liu',
     Title: 'Audio-Visual Multi-Person Keyword Spotting via Hybrid Fusion',
     },
     {
        Time:'15:20-15:30',
         Speaker: 'Jian Liu, Yahong Han',
         Title: 'Maintaining Structural Information by Pairwise Similarity for Unsupervised Domain Adaptation',
    },
    {
        Time:'15:30-15:40',
         Speaker: 'Zihao Chen, Longhao Zou,Gabriel-Miro Muntean,Xu Wang, Long Xu',
         Title: 'EdgeVR360: Edge-assisted Multiuser-oriented Intelligent 360-degree Video Delivery Scheme over Wireless Network',
    },
     ],
     []
    )

    const columns9 = React.useMemo(
      () => [
        {
          Header: '@ Outside the Conference Rooms / Haiyan Hall, Yanxi Hotel',
          columns: [
            {
              Header: 'Index',
              accessor: 'Index',
            },
            {
              Header: 'Title',
              accessor: 'Title',
            },
          ],
        },
      ],
      []
    )
  
  const data10 = React.useMemo(() =>
   [
   {
   Index: '1',
   Title: 'GLRNet: Gas Leak Recognition via Temporal Difference in Infrared Video',
   },
   {
    Index: '2',
    Title: 'ATC-WSA: Working State Analysis for Air Traffic Controllers',
    },
    {
      Index: '3',
      Title: '3D-Producer: A Hybrid and User-Friendly 3D Reconstruction System',
      },
    {
        Index: '4',
        Title: '3D Human Pose Estimation Based on Multi-feature Extraction',
    },
    {
      Index: '5',
      Title: 'Visual Localization through Virtual Views',
   },
    {
    Index: '6',
    Title: 'A Synchronized Multi-view System for Real-time 3D Hand Reconstruction',
    },
    {
    Index: '7',
    Title: 'Monocular Real-time Human Geometry Reconstruction',
    },      
    {
      Index: '8',
      Title: 'Contactless Cardiogram Reconstruction Based on the Wavelet Transform via Continuous wave Radar',
      },    
    {
        Index: '9',
        Title: 'Intelligent Data Extraction System for RNFL Examination Reports',
    },    
    {
      Index: '10',
      Title: 'Gesture Interaction for Gaming Control Based on an Interferometric Radar',
  },   
  {
    Index: '11',
    Title: 'EasyPainter: Customizing Your Own Paintings',
},   
{
  Index: '12',
  Title: 'An Accelerating Allen Brain Institute’s Large-scale Computational Model of Mice Primary Visual Cortex',
},    
{
  Index: '13',
  Title: 'NeRFingXR: An interactive XR Tool based on NeRFs',
},  
{
  Index: '14',
  Title: 'A Brain-controlled Mahjong Game with Artificial Intelligence Augmentation',
},  
{
  Index: '15',
  Title: 'VAFA: a Visually-Aware Food Analysis System for Socially-Engaged Diet Management',
},  
{
  Index: '16',
  Title: 'XIVA: An Intelligent Voice Assistant with Scalable Capabilities for Educational Metaverse',
},  
{
  Index: '17',
  Title: 'Artistic Portrait Applet, Robot, and Printer',
}, 
{
  Index: '18',
  Title: 'Sim-to-Real Hierarchical Planning and Control System for Six-legged Robot',
}, 
{
  Index: '19',
  Title: 'Weld Defect Detection and Recognition System Based on Static Point Cloud Technology',
}, 
   ],
   []
  )
    return (
        <div>
            <h4>The specific schedule can be derived in the <a href={process.env.PUBLIC_URL + '/cicai_schedule.pdf'} target="_blank">schedule document.</a></h4>
            <h1 align="center" style={{ color: "red" }}>Schedule (August 27, 2022)</h1>
            <h3>09:00-11:55 </h3>
            <h2> Tutorial Theme 1: Graph-based Machine Learning</h2>
            <Styles><Table columns={columns} data={data1} /></Styles>
            <h3>09:00-11:00 </h3>
            <h2> Tutorial Theme 2: Human Computer Interaction and Robotics</h2>
            <Styles><Table columns={columns1} data={data2} /></Styles>
            <h3>09:00-11:00 </h3>
            <h2> Tutorial Theme 3: Art and Artificial Intelligence</h2>
            <Styles><Table columns={columns2} data={data3} /></Styles>
            <h3>11:00-12:00 </h3>
            <h2> Lunch </h2>
            <h3 align='center' style={{fontfamily: "serif "}}> @ Tutorial Room</h3>    
            <h3>13:30-15:30 </h3>
            <h2> Tutorial Theme 4: Intelligence in Natural Language Understanding and Processing</h2>
            <Styles><Table columns={columns3} data={data4} /></Styles>
            <h3>13:30-16:35  </h3>
            <h2> Tutorial Theme 5: AI Digital Avatar</h2>
            <Styles><Table columns={columns4} data={data5} /></Styles>
            <h3>09:00-17:00  </h3>
            <h2> 2-day Demo Exhibitions</h2>         
            <Styles><Table columns={columns9} data={data10} /></Styles> 
            <h3>18:00-21:00  Reception Dinner</h3>
            <h3 align='center' style={{fontfamily: "serif "}}> @ Waterside, Yanqi Hotel 1st Floor</h3>  
            <br></br>
            <br></br>
            <br></br>
            <h1 align="center" style={{ color: "red" }}>Schedule (August 28, 2022)</h1>
            <h3>09:00-09:10 </h3>
            <h3 align='center' style={{fontfamily: "serif "}}> @ Haiyan Hall</h3>  
            <h2> CICAI Welcome and Opening Remarks</h2>
            <h3>09:10-09:50 </h3>
            <h2><b style={{ color: "red" }}> Keynote 1</b>, Qiang Yang, Fellow of Canadian Academy of Engineering</h2>
            <h3>Title: Trustworthy Federated Learning</h3>
            <h3>09:50-10:40 </h3>
            <h2>Oral Session 1</h2>
            <Styles><Table columns={columns5} data={data6} /></Styles>
            <h3>10:40-10:50 </h3>
            <h2>Coffee Break</h2>       
            <h3>10:50-11:40 </h3>
            <h2>Oral Session 2</h2>                 
            <Styles><Table columns={columns6} data={data7} /></Styles>
            <h3>11:40-12:00 </h3>
            <h2>Introduction of CAAI Artificial Intelligence Research</h2>     
            <h3>12:00-13:00 Lunch </h3>
            <h3 align='center' style={{fontfamily: "serif "}}> @ Waterside, Yanqi Hotel 1st Floor</h3>              
            <h3>13:30-14:10 </h3>
            <h3 align='center' style={{fontfamily: "serif "}}> @ Haiyan Hall</h3>  
            <h2><b style={{ color: "red" }}> Keynote 2</b>, Dacheng Tao, Fellow of Australian Academy of Science</h2>
            <h3>Title: More Is Different: ViTAE elevates the art of computer vision</h3>
            <h3>14:10-14:50 </h3>
            <h2>Oral Session 3</h2>                 
            <Styles><Table columns={columns7} data={data8} /></Styles>
            <h3>14:50-15:00 </h3>
            <h2>Coffee Break</h2>    
            <h3>15:00-15:40 </h3>
            <h2>Oral Session 4</h2>                 
            <Styles><Table columns={columns8} data={data9} /></Styles>
            <h3>15:40-17:00 </h3>
            <h2>Poster Exhibitions</h2>  
            <h3>17:00-17:15 </h3>
            <h2>Conference Awards and Ending</h2>  
        </div>
        )


};

export default Technical;