import React from 'react';

const Awards = ()=> (
    <div>
       

        <h2>Best Paper Award</h2>
        <h3>End-to-End Optimization of Quantization-based Structure Learning and Interventional Next-item Recommendation</h3>
        <p>Authors: Kairui Fu, Qiaowei Miao, Shengyu Zhang, Kun Kuang, Fei Wu</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page0-2023.JPG'} />

        <h2>Best Paper Runner-up Award</h2>
        <h3>MARS: An Instance-aware, Modular and Realistic Simulator for Autonomous Driving</h3>
        <p>Authors: Zirui Wu, Tianyu Liu, Liyi Luo, Zhide Zhong, Jianteng Chen,
            Hongmin Xiao, Chao Hou, Haozhe Lou, Yuantao Chen, Runyi Yang, Yuxin Huang, Xiaoyu Ye,
            Zike Yan, yongliang Shi, Yiyi Liao, Hao Zhao
        </p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page1-2023.JPG'} />

        <h2>Finalist of Best Paper Award</h2>
        <h3>Diagnosis then Aggregation: An Adaptive Ensemble
            strategy for Keyphrase Extraction
        </h3>
        <p>Authors: Jin, Xin; Liu, Qi; Yue, Linan; Liu, Ye; Zhao, Lili; Gao,
            Weibo; Gong, Zheng; Zhang, Kai; Bi, Haoyang
        </p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page2-2023.JPG'} />

        <h2>Best Student Paper Award</h2>
        <h3>An Efficient Graph Transformer Network for
            Video-based Human Mesh Reconstruction
        </h3>
        <p>Authors: Tao Tang, Yingxuan You, Ti Wang, Hong Liu</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page3-2023.JPG'} />


        <h2>Best Student Paper Runner-up Award</h2>
        <h3>Structural Recognition of Handwritten Chinese
            Characters Using a Modified Part Capsule Auto-Encoder
        </h3>
        <p>Authors: Wu, Xin-Jian; Ao, Xiang; Zhang, Ruisong; Liu, ChengLin</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page4-2023.JPG'} />

        
        <h2>Finalist of Best Student Paper Award</h2>
        <h3>Text-oriented Modality Reinforcement Network for Multimodal
            Sentiment Analysis from Unaligned Multimodal Sequences
        </h3>
        <p>Authors: Lei, Yuxuan; Yang, Dingkang; Li, Mingcheng; Wang, Shunli;
Chen, Jiawei; Zhang, Lihua
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page5-2023.JPG'} />


        <h2>Best Poster Award</h2>
        <h3>Dual-Domain Network for Restoring
            Images from Under-Display Cameras
        </h3>
        <p>Authors: Di Wang, Zhuoran Zheng, Xiuyi Jia</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page6-2023.JPG'} />

        <h2>Best Poster Runner-up Award</h2>
        <h3>How to Select the Appropriate One From
            the Trained Models for Model-Based OPE
        </h3>
        <p>Authors: Chongchong Li, Yue Wang, Zhiming Ma, Yuting Liu</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page7-2023.JPG'} />


        <h2>Finalist of Best Poster Award</h2>
        <h3>MusicGAIL: A Generative Adversarial Imitation
            Learning Approach for Music Generation
        </h3>
        <p>Authors: Yusong Liao, Hongguang Xu, Ke Xu</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page8-2023.JPG'} />

        <h2>Best Demo Award</h2>
        <h3>Enhancing Daily Life through an
            Interactive Desktop Robotics System
        </h3>
        <p>Authors: Yuhang Zheng, Qiyao Wang, Chengliang Zhong, He Liang,
            Bo Liang, Zhengxiao Han, Yupeng Zheng
        </p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page9-2023.JPG'} />


        <h2>Best Demo Runner-up Award</h2>
        <h3>A CNN-based Real-time Dense Stereo SLAM
            System on Embedded FPGA
        </h3>
        <p>Authors: Qian Huang, Yu Zhang, Jianing Zheng, Gaoxing Shang, Gang Chen, Hao Lei</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page10-2023.JPG'} />




        <h2>Finalist of Best Demo Award</h2>
        <h3>GIST: Transforming Overwhelming Information into
                Structured Knowledge with Large Language Models
        </h3>
        <p>Authors: Meng Wu, Xinyu Zhou, Gang Ma, Zhangwei Lu, Liuxin Zhang, Yu Zhang</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page11-2023.JPG'} />



        <h2>Outstanding Service</h2>
        <h3></h3>
        <p>Authors: Haozhe Lin</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page12-2023.JPG'} />



        <h2>Outstanding Service</h2>
        <h3></h3>
        <p>Authors: Jiangtao Gong</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page13-2023.JPG'} />





        <h2>GigaDetection Challenge
            First Place
        </h2>
        <h3></h3>
        <p>Member: Lantao Wang, Lilu Sun, Chenyang Lvu, Xuehui Wang <br></br>
Institution: Shanghai Jiao Tong University
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page14-2023.JPG'} />


        <h2>GigaDetection Challenge
Second Place
</h2>
        <h3></h3>
        <p>Member: Xiaochuan Li, Runze Zhang, Li Wang, Hongzhen Li <br></br>
Supervisor: Yaqian Zhao, Zhenhua Guo, Baoyu Fan <br></br>
Institution: Inspur Electronic Information Industry Co., Ltd
</p>
        
        <img width="600" src={process.env.PUBLIC_URL + '/award-page15-2023.JPG'} />


        <h2>GigaDetection Challenge
Third Place
</h2>
        <h3></h3>
        <p>Member: Han Li, Chen Liu <br></br>
Supervisor: Ping Yin, Xiaobo An <br></br>
Institution: Inspur Cloud Information Technology Co, Ltd
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page16-2023.JPG'} />

        <h2>GigaReconstruction Challenge
First Place
</h2>
        <h3></h3>
        <p>Member: Jiaxi Sun <br></br>
Supervisor: Xiaopeng Zhang, Shibiao Xu, Jiguang Zhang <br></br>
Institution: Institute of Automation Chinese Academy of Sciences
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page17-2023.JPG'} />

        <h2>GigaReconstruction Challenge
Second Place
</h2>
        <h3></h3>
        <p>Member: Sa Gao <br></br>
Institution: Huawei Technologies Co., Ltd.
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page18-2023.JPG'} />


        <h2>GigaReconstruction Challenge
Third Place
</h2>
        <h3></h3>
        <p>Member: Zizhuang Wei, Qingtian Zhu <br></br>
Supervisor: Lingyun Li, Xin Zhang, Guoping Wang <br></br>
Institution: Huawei Technologies, Peking University
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page19-2023.JPG'} />


        <h2>GigaTrajectory Challenge First Place</h2>
        <h3></h3>
        <p>Member: Mingkun Wang, Mingxu Wang, Xinge Zhu <br></br>
Supervisor: Yuexin Ma, Xiaoguang Ren, Wenjing Yang
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page20-2023.JPG'} />


        <h2>GigaTrajectory Challenge
Second Place
</h2>
        <h3></h3>
        <p>Member: Xiaochuan Li, Runze Zhang, Li Wang, Hongzhen Li <br></br>
Supervisor: Yaqian Zhao, Zhenhua Guo, Baoyu Fan <br></br>
Institution: Inspur Electronic Information Industry Co., Ltd
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page21-2023.JPG'} />


        <h2>GigaTrajectory Challenge
Third Place
</h2>
        <h3></h3>
        <p>Member: Liushuai Shi, Qi Wu, Yonghao Dong, Jinghai Duan, Qianxiong Ning <br></br>
Institution: Xi'an JiaoTong University
</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page22-2023.JPG'} />







{/* 
        <h2>Best Paper Award</h2>
        <h3>Second-order Global Attention Networks for Graph Classification and Regression</h3>
        <p>Authors: Fenyu Hu, Zeyu Cui, Shu Wu, Qiang Liu, Jinlin Wu, Liang Wang, Tieniu Tan</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page6.png'} />
        <h2>Best Paper Runner-up Award</h2>
        <h3>Connecting Patients with Pre diagnosis: A Multiple Graph Regularized Method for Mental Disorder Diagnosis</h3>
        <p>Authors: Tianqi Zhao, Ming Kong, Kun Kuang, Zhengxing Huang, Qiang Zhu, Fei Wu</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page7.png'} />
        <h2>Finalist of Best Paper Award</h2>
        <h3>A Genetic Algorithm for Causal Discovery Based on Structural Causal Model</h3>
        <p>Authors: Zhengyin Chen, Kun Liu, Wenpin Jiao</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page8.png'} />
        <h2>Best Student Paper Award</h2>
        <h3>Visual Perception Inference on Raven's Progressive Matrices by Semi-Supervised Contrastive Learning</h3>
        <p>Authors: Aihua Yin, Weiwen Lu, Sidong Wang, Hongzhi You, Ru-Yuan Zhang, Da-Hui Wang, Zonglei Zhen, Xiaohong Wan</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page3.png'} />        
        <h2>Best Student Paper Runner-up Award</h2>
        <h3>3D Face Cartoonizer: Generating Personalized 3D cartoon Faces from 2D Real Photos with a Hybrid Dataset</h3>
        <p>Authors: Ming Guo, Shunfei Wang, Zhibo Wang, Ming Lu, Xiufen Cui, Xiao Ling, Feng Xu</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page4.png'} />        
        <h2>Finalist of Best Student Paper Award</h2>
        <h3>Deep Skill Chaining with Diversity for Multi-agent Systems</h3>
        <p>Authors: Zaipeng Xie, Cheng Ji, Yufeng Zhang</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page5.png'} />  
        <h2>Best Demo Award</h2>
        <h3>GLRNet: Gas Leak Recognition via Temporal Difference in Infrared Video</h3>
        <p>Authors: Erqi Huang, Linsen Chen, Tao Lv, Xun Cao</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page0.png'} /> 
        <h2>Best Demo Runner-up Award</h2>
        <h3>Contactless Cardiogram Reconstruction Based on the Wavelet Transform via Continuous wave Radar</h3>
        <p>Authors: Shuqin Dong, Changzhan Gu, Xiaokang Yang</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page1.png'} />         
        <h2>Finalist of Best Demo Award</h2>
        <h3>Artistic Portrait Applet, Robot, and Printer</h3>
        <p>Authors: Jingjie Zhu, Lingna Dai, Chenghao Xia, Chenyang Jiang, Weiyu Weng, Yiyuan Zhang, Jinglin Zhou, Fei Gao, Peng Li, Mingrui Zhu, Nannan Wang</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page2.png'} />     
        <h2>Outstanding Service</h2>
        <p>Kun Li</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page9.png'} />
        <h2>Outstanding Service</h2>
        <p>Mengqi Ji</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page10.png'} />
        <h2>Outstanding Service</h2>
        <p>Ruiping Wang</p>
        <img width="600" src={process.env.PUBLIC_URL + '/award-page11.png'} />            */}
    </div>
);

export default Awards;