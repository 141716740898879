import React from 'react';
import speaker_1 from '../imgs/keynote_speaker_12.jpg';
import speaker_2 from '../imgs/speaker2.jpg';
import speaker_3 from '../imgs/keynote_speaker_3.png';
import speaker_4 from '../imgs/keynote_speaker_4.png';
import styles from './Speaker.module.scss';



const Speaker = ({ name, title, time, abstract, biography, paper_title, photo_url }) => {
    console.log(name);
    console.log(title);
    console.log(paper_title);
    return (
        <div className={styles['speaker']}>
            <div className={styles['speaker_center_wrapper']}>
                <h4 className={styles['subtle']}><i>{time}</i></h4>
                <img width="300" className={styles['speaker_photo']} src={photo_url} />
                <h3 >{name}</h3>
                <h4 className={styles['subtle']}>{title}</h4> <br />
                <h3>{paper_title}</h3>
            </div>
            
            <h3>Abstract</h3>
            {abstract.map((paragraph, i) => (
                <p key={name+'-abstract-p-' + i}>{paragraph}</p>
            ))}
            <h3>Biography</h3>
            <p>Qiang Yang is a Fellow of Canadian Academy of Engineering (CAE) and Royal Society of Canada (RSC), Chief Artificial Intelligence Officer of WeBank and Chair Professor of CSE Department of Hong Kong Univ. of Sci. and Tech. He is the Conference Chair of AAAI-21, President of Hong Kong Society of Artificial Intelligence and Robotics(HKSAIR) , the President of Investment Technology League (ITL) and a former President of IJCAI (2017-2019). He is a fellow of AAAI, ACM, IEEE and AAAS. His research interests include transfer learning and federated learning. He is the founding EiC of two journals: IEEE Transactions on Big Data and ACM Transactions on Intelligent Systems and Technology. His latest books are <i>Transfer Learning , Federated Learning and Practicing Federated Learning</i>.</p>
            <div className={styles['divider_light']}></div>
        </div>
    )
};

const Speaker2 = ({ name, title, time, abstract, biography, paper_title, photo_url }) => {
    console.log(name);
    console.log(title);
    console.log(paper_title);
    return (
        <div className={styles['speaker']}>
            <div className={styles['speaker_center_wrapper']}>
                <h4 className={styles['subtle']}><i>{time}</i></h4>
                <img width="300" className={styles['speaker_photo']} src={photo_url} />
                <h3 >{name}</h3>
                <h4 className={styles['subtle']}>{title}</h4> <br />
                <h3>{paper_title}</h3>
            </div>
            <h3>Biography</h3>
            {biography.map((paragraph, i) => (
                <p key={name+'-biography-p-' + i}>{paragraph}</p>
            ))}
        </div>
    )
};


const Speakers = () => {
    const SpeakerData = [
        {
            name: 'Qiang Yang',
            title: 'Fellow of Canadian Academy of Engineering (CAE) and Royal Society of Canada (RSC), Chief Artificial Intelligence Officer of WeBank and Chair Professor of CSE Department of Hong Kong Univ. of Sci. and Tech',
            paper_title: 'Trusted Federated Learning',
            photo_url: speaker_1,
            time: '',
            abstract: ['Federated Learning is an important intersection of AI and privacy computing. How to make Federated Learning more safe, trustworthy and efficient is the focus of industry and academia in the future. In my lecture, I will systematically review the progress and challenges of Federated Learning, and look forward to several important development directions.'],
            biography: ['Qiang Yang is a Fellow of Canadian Academy of Engineering (CAE) and Royal Society of Canada (RSC), Chief Artificial Intelligence Officer of WeBank and Chair Professor of CSE Department of Hong Kong Univ. of Sci. and Tech. He is the Conference Chair of AAAI-21, President of Hong Kong Society of Artificial Intelligence and Robotics(HKSAIR) , the President of Investment Technology League (ITL) and a former President of IJCAI (2017-2019). He is a fellow of AAAI, ACM, IEEE and AAAS. His research interests include transfer learning and federated learning. He is the founding EiC of two journals: IEEE Transactions on Big Data and ACM Transactions on Intelligent Systems and Technology. His latest books are Transfer Learning , Federated Learning and Practicing Federated Learning.'],
        },
    ];
    const SpeakerData2 = [
        {
            name: 'Dacheng Tao',
            title: 'Inaugural Director of the JD Explore Academy and Senior Vice President of JD.com',
            paper_title: 'More Is Different: ViTAE elevates the art of computer vision',
            photo_url: speaker_2,
            time: '',
            abstract: ['Federated Learning is an important intersection of AI and privacy computing. How to make Federated Learning more safe, trustworthy and efficient is the focus of industry and academia in the future. In my lecture, I will systematically review the progress and challenges of Federated Learning, and look forward to several important development directions.'],
            biography: ['Dacheng Tao is the Inaugural Director of the JD Explore Academy and a Senior Vice President of JD.com. He is also an advisor and chief scientist of the digital science institute in the University of Sydney. He mainly applies statistics and mathematics to artificial intelligence and data science, and his research is detailed in one monograph and over 200 publications in prestigious journals and proceedings at leading conferences. He received the 2015 Australian Scopus-Eureka Prize, the 2018 IEEE ICDM Research Contributions Award, and the 2021 IEEE Computer Society McCluskey Technical Achievement Award. He is a fellow of the Australian Academy of Science, the World Academy of Sciences, the Royal Society of NSW, AAAS, ACM, IAPR and IEEE.'],
        },
    ];
    return (
        <div>
            <h2>Keynote Speakers</h2>
            <div className={styles['divider']}></div>
            {SpeakerData.map((data, i) => (
                <Speaker {...data} key={i} />
            ))}
            {SpeakerData2.map((data, i) => (
                <Speaker2 {...data} key={i} />
            ))}
        </div>
    )
};

export default Speakers;