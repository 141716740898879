import React from 'react';
const Conference = ()=> (
    <div>
        <br></br>
        <p><b>CICAI Conference is Postponed to August 6th-7th, 2022.</b></p>
        <p>Dear CICAI authors,</p>
        <p>CICAI 2022 was originally scheduled on May 29th-30th, 2022. Recently, the 2022 Global Artificial Intelligence Technology Conference, GATIC 2022 has been postponed to August 6th-7th, 2022. (see <a href="https://gaitc.caai.cn/" target="_blank">GATIC</a> for more details). </p>
        <p>To facilitate applicants to participate in both CICAI 2022 and GATIC 2022, the organizing committee has decided to reschedule CICAI to August 6th-7th, 2022, accordingly. </p>
        <p>The date for the notification of paper acceptance will be postponed to May 15th, 2022. </p>
        <p>We apologize for the inconveniences that might result from this alteration. On behalf of the committee, we would like to thank you for your understanding. We are very much looking forward to welcoming you in Beijing, China. </p>
        <p>Best regards,<br></br>CICAI 2022 Committee</p>
    </div>
);

export default Conference;