import React from 'react';

const Deadlines = ()=> (
    <div>
        <h2>Deadlines</h2>
        <table>
          <tr>
          <td>Paper Submission Due</td>
            <td><del>May. 30, 2023</del></td>
            <td>June. 30, 2023</td>
            
          </tr>
          <tr>
          <td>Notification of Paper Acceptance</td>
            <td><del>June. 30, 2023</del></td>
            <td>July. 10, 2023</td>
            
          </tr>
          <tr>
          <td>Camera-ready Papers Due</td>
            <td><del>July. 15, 2023</del></td>
            <td>July. 20, 2023</td>
            
          </tr>
          <tr>
          <td>Conference</td>
            <td>July. 22 - 23, 2023</td>
            <td></td>
          </tr>

          
        </table>
    </div>
);

export default Deadlines;